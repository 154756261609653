import React, { Suspense, useEffect, useState, useCallback } from "react";
import { Switch, Redirect } from "react-router-dom";
import ErrorPage from "../errors/ErrorPage";
import AdminRoute from "./components/AdminRoute";
import {
  UsersListing,
  UserForm,
  ClientListing,
  ClientForm,
  ProjectListing,
  ProjectForm,
  Timer,
  Approvals,
  Reports,
  RitualListing,
  RitualForm,
  UserReport,
  ProposalListing,
  ProposalDetails,
  EndOfPeriod,
  CheckListing,
  EditProfileForm,
  OrgChartPage,
  CoordinationListing,
  PayingAreaListing,
  LogScreen,
  FactoryListing,
  FactoryForm,
  ClosingDateListing,
  ClosingDateForm,
  MessageWhatsapp,
  TrainingListing,
  TrainingForm,
  TrainingSubgroupListing,
  TrainingSubgroupForm,
  TrainingGroupListing,
  TrainingGroupForm,
  HolidayListing,
  HolidayForm,
  ProjectTypeListing,
  ProjectTypeForm,
  ConciliationListing,
  ProposalDetailForm,
  ProposalForm,
  ReportProjectWithoutLaunched,
  ReportUserWithoutLaunched,
  ReportUserAllocated,
  ProjectReport,
  NotificationListing,
  NotificationForm,
  NotificationDetails,
  EndOfPeriodFlex,
  ClosingDateFlexListing,
  ClosingDateFlexForm,
  EndOfPeriodFlexListing
} from "./pages";
import Swal from "sweetalert2";
import { InexApiService } from "../../services/InexApiService";
import store from "../../store/store";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import moment from "moment";

export default function AdminPage() {
  const api = new InexApiService();
  const { auth } = store.getState();
  const [width, setWidth] = useState(window.innerWidth);
  const [notifications, setNotifications] = useState([]);

  const fetchNotifications = useCallback(async () => {
    try {
      const response = await api.makeHttpRequest({
        url: `/notification/user`
      });
      setNotifications(response)

      if (response && response.length > 0) {
        Swal.fire({
          title: "Avisos",
          html: `<div id="accordion">${response.map((notification) => `<div class="card" style="margin: 0px;">
                  <div class="card-header" id="heading_${notification.idNotification}" data-toggle="collapse" data-target="#collapse_${notification.idNotification}" aria-expanded="true" aria-controls="collapse_${notification.idNotification}" style="padding: 0px;cursor:pointer">
                    <h5 class="mb-0" style="text-align: left;padding: 5px 10px">
                      <b>${notification.title}</b> Criado em: ${moment(notification.creationDate).format('DD/MM/YYYY HH:mm:ss')}
                    </h5>
                  </div>
                  <div id="collapse_${notification.idNotification}" class="collapse" aria-labelledby="heading_${notification.idNotification}" data-parent="#accordion">
                    <div class="card-body" style="text-align: left;">
                      <div><span>${notification.message}</span></div>
                      <div>${notification.link ? `<a href="${notification.link}">${notification.link}</a>` : ''}</div>
                      <div style="text-align: center;">
                        <button class="btn btn-primary ciente-button" data-id="${notification.idNotification}" style="color: #fff;background-color: #4672c5; transition: background-color 0.3s ease;" onmouseover="this.style.backgroundColor='#365A9D';" onmouseout="this.style.backgroundColor='#4672c5';">Ciente</button>
                      </div>
                    </div>
                  </div>
                </div>`).join('')}</div>`,
          icon: "info",
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonText: "Fechar"
        });

        document.querySelectorAll('.ciente-button').forEach(button => {
          button.addEventListener('click', (event) => {
            const notificationId = event.currentTarget.getAttribute('data-id');
            handleCiente(notificationId);
          });
        });
      } else {
        document.querySelectorAll('.swal2-cancel').forEach(button => {
          button.click();
        });
      }
    } catch {}
  }, []);

  const handleCiente = async (notificationId) => {
    try {
      await api.makeHttpRequest({
        url: `/notification/aware/`+notificationId,
        method: "PUT"
      }).finally(() => {
        fetchNotifications()
      });
    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data ? e.response.data : "Erro ao dar ciência.",
        e?.response?.data ? "warning" : "error"
      )
    }
  };

  const resizeEvent = useCallback(() => {
    if (window.innerWidth !== width) {
      setWidth(window.innerWidth);
    }
  }, []);

  useEffect(() => {
    fetchNotifications()
    window.addEventListener("resize", resizeEvent);
    return () => window.removeEventListener("resize", resizeEvent);
  }, []);

  return (
    <Suspense>
      <Switch>
        <Redirect exact from="/admin" to="/admin/timer" />

        {/* SCREEN LOGS */}
        <AdminRoute permissionLevel="ADMIN" exact path="/admin/:slug/logs" component={() => <LogScreen width={width} />} />
        <AdminRoute permissionLevel="ADMIN" exact path="/admin/:slug/logs/:id" component={() => <LogScreen width={width} />} />

        {/* TIMER */}
        <AdminRoute permissionLevel="CONSULTANT" exact path="/admin/timer" component={() => <Timer width={width} />} />

        {/* APROVAÇÕES */}
        <AdminRoute permissionLevel="FINANCIAL" exact path="/admin/aprovacoes" component={() => <Approvals width={width} />} />

        {/* RELATÓRIOS */}
        <AdminRoute permissionLevel="FINANCIAL" exact path="/admin/relatorios" component={() => <Reports width={width} />} />
        <AdminRoute permissionLevel="FINANCIAL" exact path="/admin/profissionais/relatorio" component={(props) => <UserReport width={width} {...props} />} />
        <AdminRoute permissionLevel="FINANCIAL" exact path="/admin/profissionais/relatorio-sem-lancamento" component={(props) => <ReportUserWithoutLaunched width={width} {...props} />} />
        <AdminRoute permissionLevel="FINANCIAL" exact path="/admin/profissionais/relatorio-alocados" component={(props) => <ReportUserAllocated width={width} {...props} />} />

        {/* FECHAMENTO */}
        <AdminRoute permissionLevel="FINANCIAL" exact path="/admin/fechamento-ciclo" component={() => <EndOfPeriod width={width} />} />
        <AdminRoute permissionLevel="FINANCIAL" exact path="/admin/fechamento-flexivel" component={() => <EndOfPeriodFlex width={width} />} />
        <AdminRoute permissionLevel="FINANCIAL" exact path="/admin/fechamento-flexivel/projetos" component={() => <EndOfPeriodFlexListing width={width} />} />

        {/* CONCILIAÇÃO */}
        <AdminRoute permissionLevel="FINANCIAL" exact path="/admin/conciliacoes" component={() => <ConciliationListing width={width} />} />

        {/* RITUAIS */}
        <AdminRoute permissionLevel="COMMERCIAL" exact path="/admin/rituais" component={() => <RitualListing width={width} />} />
        <AdminRoute permissionLevel="OPERATION" exact path="/admin/rituais/:id/editar" component={RitualForm} />

        {/* PROFISSIONAIS */}
        <AdminRoute permissionLevel="RECRUITMENT" exact path="/admin/profissionais" component={() => <UsersListing width={width} />} />
        <AdminRoute permissionLevel="RECRUITMENT_WITCHOUT_COMMERCIAL" path="/admin/profissionais/adicionar" component={UserForm} />
        <AdminRoute permissionLevel="RECRUITMENT_WITCHOUT_COMMERCIAL" path="/admin/profissionais/:id/editar" component={UserForm} />

        {/* EDITAR */}
        <AdminRoute permissionLevel="CONSULTANT" path="/admin/editar/perfil" component={EditProfileForm} />

        {/* CLIENTES */}
        <AdminRoute permissionLevel="RECRUITMENT" exact path="/admin/clientes" component={() => <ClientListing width={width} />} />
        <AdminRoute permissionLevel="OPERATION" path="/admin/clientes/adicionar" component={ClientForm} />
        <AdminRoute permissionLevel="OPERATION" path="/admin/clientes/:id/editar" component={ClientForm} />

        {/* TIPOS DE PROJETO */}
        <AdminRoute permissionLevel="FINANCIAL" exact path="/admin/tipos-de-projeto" component={() => <ProjectTypeListing width={width} />} />
        <AdminRoute permissionLevel="OPERATION" path="/admin/tipos-de-projeto/adicionar" component={ProjectTypeForm} />
        <AdminRoute permissionLevel="OPERATION" path="/admin/tipos-de-projeto/:id/editar" component={ProjectTypeForm} />

        {/* PROJETOS */}
        <AdminRoute permissionLevel="FINANCIAL" exact path="/admin/projetos" component={() => <ProjectListing width={width} />} />
        <AdminRoute permissionLevel="OPERATION" path="/admin/projetos/adicionar" component={ProjectForm} />
        <AdminRoute permissionLevel="OPERATION" path="/admin/projetos/:id/editar" component={ProjectForm} />
        <AdminRoute permissionLevel="FINANCIAL" exact path="/admin/projetos/relatorio" component={(props) => <ProjectReport width={width} {...props} />} />

        <AdminRoute permissionLevel="FINANCIAL" path="/admin/organograma" component={OrgChartPage} />
        <AdminRoute permissionLevel="OPERATION" exact path="/admin/projetos/relatorio-sem-lancamento" component={(props) => <ReportProjectWithoutLaunched width={width} {...props} />} />
        
        {/* COORDENACAO */}
        <AdminRoute permissionLevel="FINANCIAL" exact path="/admin/coordenacoes" component={() => <CoordinationListing width={width} />} />

        {/* AREA PAGADORA */}
        <AdminRoute permissionLevel="FINANCIAL" path="/admin/area-pagadora" component={() => <PayingAreaListing width={width} />} />

        {/* FABRICA */}
        <AdminRoute permissionLevel="OPERATION" exact path="/admin/fabrica" component={() => <FactoryListing width={width} />} />
        <AdminRoute permissionLevel="OPERATION" path="/admin/fabrica/adicionar" component={FactoryForm} />
        <AdminRoute permissionLevel="OPERATION" path="/admin/fabrica/:id/editar" component={FactoryForm} />

        {/* PROPOSTAS */}
        <AdminRoute permissionLevel="FINANCIAL" exact path="/admin/propostas" component={() => <ProposalListing width={width} />} />
        <AdminRoute permissionLevel="FINANCIAL" path="/admin/propostas/adicionar" component={ProposalForm} />
        <AdminRoute permissionLevel="FINANCIAL" path="/admin/propostas/:id/editar" component={ProposalForm} />
        <AdminRoute permissionLevel="FINANCIAL" path="/admin/propostas/:id/detalhes" component={ProposalDetails} />
        <AdminRoute permissionLevel="FINANCIAL" path="/admin/propostas/:id/adicionar-detalhes" component={ProposalDetailForm} />
        <AdminRoute permissionLevel="FINANCIAL" path="/admin/propostas/detalhes/:id/editar" component={ProposalDetailForm} />

        {/* CHECK */}
        <AdminRoute permissionLevel="COMMERCIAL_WITCHOUT_OPERATION" exact path="/admin/check" component={() => <CheckListing width={width} />} />

        {/* ADMINISTRATIVO */}
        <AdminRoute permissionLevel="OPERATION" exact path="/admin/data-de-fechamento" component={() => <ClosingDateListing width={width} />} />
        <AdminRoute permissionLevel="OPERATION" path="/admin/data-de-fechamento/adicionar" component={ClosingDateForm} />
        <AdminRoute permissionLevel="OPERATION" path="/admin/data-de-fechamento/:id/editar" component={ClosingDateForm} />
        <AdminRoute permissionLevel="OPERATION" exact path="/admin/mensagem-whatsapp" component={() => <MessageWhatsapp width={width} />} />
        <AdminRoute permissionLevel="OPERATION" exact path="/admin/feriados" component={() => <HolidayListing width={width} />} />
        <AdminRoute permissionLevel="OPERATION" path="/admin/feriados/adicionar" component={HolidayForm} />
        <AdminRoute permissionLevel="OPERATION" path="/admin/feriados/:id/editar" component={HolidayForm} />
        <AdminRoute permissionLevel="COMMERCIAL" exact path="/admin/notificacoes" component={() => <NotificationListing width={width} />} />
        <AdminRoute permissionLevel="COMMERCIAL" path="/admin/notificacoes/adicionar" component={NotificationForm} />
        <AdminRoute permissionLevel="COMMERCIAL" path="/admin/notificacoes/:id/detalhes" component={NotificationDetails} />
        <AdminRoute permissionLevel="COMMERCIAL" path="/admin/notificacoes/:id/editar" component={NotificationForm} />
        <AdminRoute permissionLevel="OPERATION" exact path="/admin/data-fechamento-flexivel" component={() => <ClosingDateFlexListing width={width} />} />
        <AdminRoute permissionLevel="OPERATION" path="/admin/data-fechamento-flexivel/adicionar" component={ClosingDateFlexForm} />
        <AdminRoute permissionLevel="OPERATION" path="/admin/data-fechamento-flexivel/:id/editar" component={ClosingDateFlexForm} />

        {/* TREINAMENTO */}
        <AdminRoute permissionLevel="CONSULTANT" exact path="/admin/treinamentos" component={() => <TrainingListing width={width} />} />
        <AdminRoute permissionLevel="OPERATION" path="/admin/treinamentos/adicionar" component={TrainingForm} />
        <AdminRoute permissionLevel="OPERATION" path="/admin/treinamentos/:id/editar" component={TrainingForm} />
        <AdminRoute permissionLevel="CONSULTANT" exact path="/admin/treinamento-subgrupos" component={() => <TrainingSubgroupListing width={width} />} />
        <AdminRoute permissionLevel="OPERATION" path="/admin/treinamento-subgrupos/adicionar" component={TrainingSubgroupForm} />
        <AdminRoute permissionLevel="OPERATION" path="/admin/treinamento-subgrupos/:id/editar" component={TrainingSubgroupForm} />
        <AdminRoute permissionLevel="CONSULTANT" exact path="/admin/treinamento-grupos" component={() => <TrainingGroupListing width={width} />} />
        <AdminRoute permissionLevel="OPERATION" path="/admin/treinamento-grupos/adicionar" component={TrainingGroupForm} />
        <AdminRoute permissionLevel="OPERATION" path="/admin/treinamento-grupos/:id/editar" component={TrainingGroupForm} />

        {/* ERROR */}
        <AdminRoute permissionLevel="CONSULTANT" path="/admin/*" component={ErrorPage} />
      </Switch>
    </Suspense>
  );
}
