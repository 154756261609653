import React from "react";
import { connect } from "react-redux";
import { PageTitle } from "../PageTitle";
import { Container } from "./styles";
import { InexApiService } from "../../../../services/InexApiService";
import { InputField } from "../../../admin/components/InputField";
import { Form, Col } from "react-bootstrap";
import styled from "styled-components";
import Swal from "sweetalert2";
import IsValidEmail from "../../../../utils/validators/IsValidEmail";
import IsValidPhone from "../../../../utils/validators/IsValidPhone";
import formatTel from "../../../../utils/FormatTel";

const initialState = {
  nome: "",
  email: "",
  telefone: "",
  titulo: "",
  descricao: ""
}

const Button = styled.button`
  position: relative;
  width: 120px;
  padding: 14px 0;
  font-family: "Cera Pro Bold", sans-serif;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  text-transform: uppercase;
  background: #fff;
  border-radius: 4px;
  color: ${({ color }) => (color ? color : "#4D4C4D")};
  border: ${({ color }) =>
    color ? `1px solid ${color}` : "1px solid #4D4C4D"};

  &:disabled {
    background: #807e80;
    border-color: ${({ color }) => (color ? "#807E80" : "#4D4C4D88")};
  }

  ${({ variant }) =>
    variant === "primary" &&
    `
    background: #4672C5;
    color: white;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    margin-right: 0px;
    `};
`;

class ComplaintsHub extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  afterSubmit = async () => {
    await Swal.fire({
      title: "Sucesso!",
      text: "Denuncia enviada com sucesso.",
      icon: "success",
      cancelButtonText: "Sair"
    });
    this.setState({
      nome: "",
      email: "",
      telefone: "",
      titulo: "",
      descricao: ""
    })
  };


  handleSubmit = async () => {
    const reqBase = { method: "POST", url: `/complaint/save` }
    if (this.state.titulo === "" || this.state.descricao === "" ){
      Swal.fire(
        "Erro!",
        "É necessário preencher o Título e a Descrição da Ocorrência",
        "error"
      );
    } else {
      const api = new InexApiService();
      await api.makeHttpRequest({
        ...reqBase,
        data: {
          name: this.state.nome,
          email: this.state.email,
          phone: this.state.telefone.replace('-', '').replace('(', '').replace(')', '').replaceAll(' ', ''),
          title: this.state.titulo,
          description: this.state.descricao
        }
      });
      this.afterSubmit();
    }
  }

  handleChange = e => {
    if (e.target.name === "telefone") {
      this.setState({
        [e.target.name]: formatTel(e.target.value)
      });
      return;
    }

    this.setState({
      [e.target.name]: e.target.value
    });
  };

  render() {
    const {
      nome,
      email,
      telefone,
      titulo,
      descricao      
    } = this.state;

    return (
      <>
      <Container>
        <PageTitle
          text="Formulário de Denúncia"
          search={undefined}
          setSearch={""}
        />
        
        <Form className="form-admin">
          <Form.Row>
            <Form.Group as={Col} lg="4" xs="12">
              <InputField
                type="text"
                name="nome"
                label="Gostaria de se indentificar?"
                value={nome}
                maxLength="100"
                placeholder="Digite seu nome"
                onChange={this.handleChange}
                isInvalid={""}
              />
            </Form.Group>
            <Form.Group as={Col} lg="4" xs="12">
              <InputField
                type="text"
                name="email"
                label="Gostaria de informar seu email?"
                value={email}
                maxLength="30"
                placeholder="Digite seu email"
                onChange={this.handleChange}
                isInvalid={email && !IsValidEmail(email)}
              />
            </Form.Group>
            <Form.Group as={Col} lg="4" xs="12">
              <InputField
                type="text"
                name="telefone"
                label="Gostaria de informar telefone?"
                value={formatTel(telefone)}
                maxLength="19"
                placeholder="Digite seu telefone"
                onChange={this.handleChange}
                isInvalid={telefone && !IsValidPhone(telefone)}
              />
            </Form.Group>
          </Form.Row>
  
          <Form.Row>
            <Form.Group as={Col} lg="12" xs="12">
              <InputField
                type="text"
                name="titulo"
                label="Título"
                value={titulo}
                maxLength="200"
                placeholder="Dê um título a ocorrência"
                onChange={this.handleChange}
                isInvalid={""}
                required={true}
              />
            </Form.Group>
          </Form.Row>
  
          <Form.Row >
            {/* Historico */}
            <Form.Group as={Col} lg="12" xs="12">
              <InputField
                as="textarea"
                name="descricao"
                label="Nos conte o ocorrido"
                placeholder="Detalhe o máximo possivel"
                value={descricao}
                required={true}
                maxLength="1000"
                style={{ height: "300px"}}
                onChange={this.handleChange}
              />
            </Form.Group>
          </Form.Row>

          <Form.Row style={{ justifyContent: 'flex-end', marginRight: '10px' }}>
            <Button
              variant="primary"
              type="button"
              color="#4672C5"
              onClick={this.handleSubmit}
            >
              Enviar
            </Button>
              
          </Form.Row>

        </Form>
        



      </Container>
      
    </>
    );
  } 
}

const mapStoreToProps = ({ auth }) => ({
  user: auth.user
});

export default connect(mapStoreToProps)(ComplaintsHub);
