import React from "react";
import { Form, Col, Card } from "react-bootstrap";
import Swal from "sweetalert2";
import { InexApiService } from "../../../../services/InexApiService";
import FormActions from "../../components/FormActions";
import Loading from "../../../home/components/Loading";
import { InputField } from "../../components/InputField";
import { StyledCardBody } from "../../components/AdminCard/style";
import { AdminTitle } from "../../components/AdminTitle";
import moment from "moment";
import styled from "styled-components";

const Button = styled.button`
  position: relative;
  width: 50px;
  height: 50px;
  font-family: "Cera Pro Bold", sans-serif;
  font-weight: normal;
  font-size: 25px;
  line-height: 150%;
  text-transform: uppercase;
  background: #fff;
  margin-right: 8px;
  border-radius: 4px;
  color: ${({ color }) => (color ? color : "#4D4C4D")};
  border: ${({ color }) =>
    color ? `1px solid ${color}` : "1px solid #4D4C4D"};

  &:disabled {
    background: #807e80;
    border-color: ${({ color }) => (color ? "#807E80" : "#4D4C4D88")};
  }

  ${({ variant }) =>
    variant === "primary" &&
    `
    background: #4672C5;
    color: white;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    margin-right: 0px;
    `};

  ${({ variant }) =>
    variant === "danger" &&
    `
    background: red;
    color: white;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    margin-right: 0px;
    `};
`;

const initialState = {
  client: null,
  referenceDate: null,
  flexiClosureDates: [],
  newFlexiClosureDateIndex: -1,
  clientOptions: [],
  project: null,
  projectOptions: [],
  isLoading: false
};

export default class ClosingDateFlexForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
      isEditing: false
    };

    this.api = new InexApiService();

    this.handleChange = this.handleChange.bind(this);
    this.onCleanForm = this.onCleanForm.bind(this);
    this.submit = this.submit.bind(this);
    this.loadClients = this.loadClients.bind(this);
    this.loadProjects = this.loadProjects.bind(this);
    this.loadFlexiClosureDates = this.loadFlexiClosureDates.bind(this);
    this.handleAddFlexiClosureDate = this.handleAddFlexiClosureDate.bind(this);
    this.getFlexiClosureDates = this.getFlexiClosureDates.bind(this);
  }

  async componentDidMount() {
    const isEditing = !!this.props.match.params.id;
    this.setState({ isLoading: true, isEditing });
    try {
      if (this.props.match.params.id) {
        const params = this.props.match.params.id.split('-');
        const date = new Date(Number(params[3]), Number(params[2]), 1);
        this.loadClients(Number(params[0]))
        this.loadProjects(Number(params[0]), Number(params[1]))
        this.setState({
          referenceDate: date
        })
        await this.loadFlexiClosureDates(params[1], date);
      } else {
        this.loadClients();
      }
    } catch (error) {
      Swal.fire(
        "Erro!",
        error?.response ? error?.response?.data : "Ocorreu um erro ao carregar os dados, tente novamente mais tarde!",
        "error"
      );
    }
    this.setState({ isLoading: false });
  }

  loadClients = async (id) => {
    const clients = await this.api.makeHttpRequest({
      url: "/professional/clients"
    });

    const formattedOptions =
        clients.length > 0
        ? clients.map(c => ({ label: c.name, value: c.idClient }))
        : [];

    if (id) {
      this.setState({
        client: formattedOptions.find((c) => c.value === id),
        clientOptions: formattedOptions
      });
    } else {
      this.setState({
        clientOptions: formattedOptions
      });
    }
  };

  loadProjects = async (idClient, idProject) => {
    const projects = await this.api.makeHttpRequest({
      url: "/project/flexi-closure-by-client/" + idClient
    });

    const formattedOptions =
        projects.length > 0
        ? projects.map(c => ({ label: c.name, value: c.idProject }))
        : [];
    
    if (idProject) {
      this.setState({
        project: formattedOptions.find((c) => c.value === idProject),
        projectOptions: formattedOptions
      });
    } else {
      this.setState({
        projectOptions: formattedOptions,
        project: null,
        referenceDate: null
      });
    }
  };

  loadFlexiClosureDates = async (id, period) => {
    const response = await this.api.makeHttpRequest({
      url: `/flexiclosuredate/by-project-and-period/${id}?month=${period.getMonth()+1}&year=${period.getFullYear()}`
    });

    var flexiClosureDates = response.map((res) => {
      return {
        idFlexiClosureDate: res.idFlexiClosureDate,
        startDate: moment(res.startDate).toDate(),
        endDate: moment(res.endDate).toDate(),
        index: res.index,
        timerExist: res.timerExist
      }
    })

    this.setState({
      flexiClosureDates: flexiClosureDates??[],
      newFlexiClosureDateIndex: flexiClosureDates.length - 1
    });
  };

  getFlexiClosureDates = async (id, period) => {
    const response = await this.api.makeHttpRequest({
      url: `/flexiclosuredate/by-project-and-period/${id}?month=${period.getMonth()+1}&year=${period.getFullYear()}`
    });

    var flexiClosureDates = response.map((res) => {
      return {
        idFlexiClosureDate: res.idFlexiClosureDate,
        startDate: moment(res.startDate).toDate(),
        endDate: moment(res.endDate).toDate(),
        index: res.index,
        timerExist: res.timerExist
      }
    })

    return flexiClosureDates
  };

  handleAddFlexiClosureDate = async () => {
    const newIndex = this.state.newFlexiClosureDateIndex + 1

    const initialPeriod = {
      startDate: this.state.referenceDate,
      endDate: this.state.referenceDate
    }
    if (newIndex === 0) {
      const lastFlexiClosureDates = await this.getFlexiClosureDates(this.state.project.value, moment(this.state.referenceDate).subtract(1, 'month').toDate())
      const lastFlexiClosureDate = lastFlexiClosureDates.reduce((max, current) => {
        return moment(current?.endDate).isAfter(max?.endDate) ? current : max;
      }, lastFlexiClosureDates[0]);

      if (lastFlexiClosureDate && moment(lastFlexiClosureDate?.endDate).month() === moment(this.state.referenceDate).month() - 1) {
        const days = moment(lastFlexiClosureDate?.endDate).diff(moment(lastFlexiClosureDate.startDate), 'days') + 1;
        const startDate = moment(lastFlexiClosureDate.endDate).add(1, 'day');
        let endDate = moment(startDate).add(days, 'day');

        if (endDate.isBefore(this.state.referenceDate)) {
          endDate = moment(this.state.referenceDate)
        }

        initialPeriod.startDate = startDate.toDate();
        initialPeriod.endDate = endDate.toDate()
      }
    } else {
      const lastFlexiClosureDate = this.state.flexiClosureDates[this.state.newFlexiClosureDateIndex]

      if (lastFlexiClosureDate) {
        const days = moment(lastFlexiClosureDate.endDate).diff(moment(lastFlexiClosureDate.startDate), 'days') + 1;
        const endDate = moment(lastFlexiClosureDate.endDate).add(days, 'day').isAfter(moment(this.state.referenceDate).endOf('month')) ? moment(this.state.referenceDate).endOf('month') : moment(lastFlexiClosureDate.endDate).add(days, 'day');
        
        initialPeriod.startDate = moment(lastFlexiClosureDate.endDate).add(1, 'day').toDate()
        initialPeriod.endDate = endDate.toDate()
      }
    }    
    
    var flexiClosureDates = [
      ...this.state.flexiClosureDates, 
      {
        startDate: initialPeriod.startDate,
        endDate: initialPeriod.endDate,
        index: newIndex
      }
    ]
    
    this.setState({
      flexiClosureDates: flexiClosureDates,
      newFlexiClosureDateIndex: newIndex
    });
  }

  handleRemoveFlexiClosureDate = async (index) => {
    var newFlexiClosureDates = this.state.flexiClosureDates.map((fcd) => {
      if (fcd && fcd.index !== index) {
        return fcd
      }
    }).filter((fcd) => fcd !== undefined).map((newFcd, index) => {
      return {
        ...newFcd,
        index
      }
    }); 

    this.setState({
      flexiClosureDates: newFlexiClosureDates,
      newFlexiClosureDateIndex: newFlexiClosureDates.length-1
    });
  }

  submit = async e => {
    e.preventDefault();
    const id = this.props.match.params.id;
    this.setState({ isLoading: true });
    try {
      const reqBase = { method: "POST", url: "/flexiclosuredate/save" };

      const data = {
        idProject: this.state.project.value,
        periodDate: moment(this.state.referenceDate).startOf('day').toDate(),
        flexiClosureDates: this.state.flexiClosureDates.map((fcd) => {
          fcd.periodDate = moment(this.state.referenceDate).startOf('day').toDate()
          fcd.startDate = moment(fcd.startDate).startOf('day').toDate()
          fcd.endDate = moment(fcd.endDate).startOf('day').toDate()
          return fcd
        })
      }

      await this.api.makeHttpRequest({
        ...reqBase,
        data
      });

      this.afterSubmit();
    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data
          ? e.response.data
          : "Erro ao cadastrar data de fechamento, tente novamente mais tarde.",
        "error"
      );
    }

    this.setState({ isLoading: false });
  };

  afterSubmit = async () => {
    this.setState({
      ...initialState,
      resetInput: true
    });

    const { isEditing } = this.state;

    const { value } = await Swal.fire({
      title: "Sucesso!",
      text: "Dados salvos com sucesso.",
      icon: "success",
      showCancelButton: !isEditing,
      confirmButtonText: isEditing ? "Ok" : "Novo cadastro",
      cancelButtonText: "Sair"
    });

    if (!value || isEditing) {
      this.props.history.push("/admin/data-fechamento-flexivel");
    }

    if (value && !isEditing) {
      this.loadClients()
    }
  };

  handleChange = e => {
    if (e.target.name.includes('flexiClosure')) {
      const nameSplitted = e.target.name.split('-')
      this.state.flexiClosureDates.map((fcd) => {
        if (fcd.index === parseInt(nameSplitted[1])) 
        {
          (nameSplitted[2] === 'startDate') ?
            fcd.startDate = e.target.value
          :
            fcd.endDate = e.target.value
          return fcd
        }
        else 
          return fcd;
      })
    }

    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleChangeReferenceDate = e => {
    const date = e.target.value;
    this.loadFlexiClosureDates(this.state.project.value, date);
    this.setState({
      referenceDate: date,
    })
  };

  handleChangeClient = (client, event) => {
    this.setState({ client: client });
    this.loadProjects(client.value)
  };

  handleChangeProject = (project) => {
    const now = moment().startOf('month').startOf('day').toDate()

    this.loadFlexiClosureDates(project.value, now)

    this.setState({ 
      project: project,
      referenceDate: now
    });
  };

  onCleanForm = async () => {
    if (this.props.match.params.id) {
      this.setState({ isLoading: true });

      try {
        await this.loadClosingDate(this.props.match.params.id);
      } catch (e) {
        Swal.fire(
          "Erro",
          "Problema ao reverter as alterações, tente mais tarde",
          "error"
        );
      }

      this.setState({ isLoading: false });
    } else {
      this.setState({
        ...initialState,
        resetInput: true
      });
    }
  };

  render() {
    if (this.state.isEditing) {
      window.setPageTitle("Atualizar Data de Fechamento");
    } else {
      window.setPageTitle("Cadastro de Data de Fechamento");
    }

    const {
      isLoading,
      isEditing,
      client_id,
      client,
      referenceDate,
      startDates,
      endDates,
      closingDates,
      clientOptions,
      project,
      projectOptions
    } = this.state;

    const getMinDate = (index) => this.state.endDates[index];

    const getMaxDate = (index) => moment(this.state.referenceDate).hour(5).month(index).add(1, 'month').date(0).toDate();
    
    const getMinStartDate = (index) => {
      if (index !== 0) {
        const lastFlexiClosureDate = this.state.flexiClosureDates[index - 1]
        return moment(lastFlexiClosureDate.endDate).add(1, 'day').toDate()
      } else {
        return moment(this.state.referenceDate).subtract(1, 'month').startOf('month').toDate()
      }
    }

    const getMaxStartDate = (index) => {
      if (this.state.flexiClosureDates.length !== index + 1) {
        const nextFlexiClosureDate = this.state.flexiClosureDates[index + 1]
        return moment(nextFlexiClosureDate.startDate).subtract(1, 'day').toDate()
      } else {
        return moment(referenceDate).endOf('month').toDate()
      }
    }

    return (
      <>
        <Loading isLoading={isLoading} />
        <AdminTitle title="Dados da Data de Fechamento Flexível" disableHeader />
        <Card className="mt-4">
          <StyledCardBody>
            <Form style={{ flex: "1" }}>
              {/* row 1 */}
              <Form.Row style={{marginBottom: '20px'}}>
                {/* Cliente */}
                <Form.Group as={Col} lg="4" xs="12">
                  <InputField
                    as="select2"
                    name="client"
                    label="Cliente"
                    value={client}
                    onChange={this.handleChangeClient}
                    placeholder="Selecione a empresa"
                    options={clientOptions}
                    closeMenuOnSelect
                    required
                  />
                </Form.Group>

                {/* Projeto */}
                <Form.Group as={Col} lg="6" xs="12">
                  <InputField
                    as="select2"
                    name="project"
                    label="Projeto"
                    value={project}
                    onChange={this.handleChangeProject}
                    placeholder="Selecione o projeto"
                    options={projectOptions}
                    closeMenuOnSelect
                    required
                  />
                </Form.Group>

                {/* Mês / Ano */}
                <Form.Group as={Col} lg="2" xs="12">
                    <InputField
                        type="date"
                        name="referenceDate"
                        dateFormat="MMM/yyyy"
                        label="Mês/Ano"
                        showMonthYearPicker
                        value={referenceDate}
                        placeholder="Selecione a ano"
                        onChange={this.handleChangeReferenceDate}
                        disabled={client === null || project === null}
                        required 
                    />
                </Form.Group>
              </Form.Row>
              { 
                this.state.referenceDate && 
                (
                  this.state.flexiClosureDates.length === 0 ? 
                  true :
                  moment(this.state.flexiClosureDates[this.state.flexiClosureDates.length-1].endDate).startOf('day').isBefore(moment(this.state.referenceDate).endOf('month').startOf('day')) 
                ) && 
                <Form.Row style={{display:'flex', alignItems: 'center', marginLeft: '10px'}}>
                  <h5 style={{margin: 0}}>{moment(this.state.referenceDate).format('MMM')[0].toLocaleUpperCase()}{moment(this.state.referenceDate).format('MMMM').substring(1)}</h5>
                  <Form.Group as={Col} lg="1" xs="1">
                    <Button
                      variant="primary"
                      type="button"
                      color="#4672C5"
                      onClick={this.handleAddFlexiClosureDate} 
                      style={{margin: '0 4px'}}
                    >
                      <span className="text-light">+</span>
                    </Button>
                  </Form.Group>
                </Form.Row>
              }
              { 
                this.state.referenceDate &&
                this.state.flexiClosureDates.map((flexiClosureDate) =>
                  flexiClosureDate !== undefined &&
                  <Form.Row style={{marginBottom: '20px'}} key={`flexiClosureDate-${flexiClosureDate?.index}`}>
                    {/* Inicio do Período */}
                    <Form.Group as={Col} lg="2" xs="12">
                      <InputField
                        type="date"
                        name={`flexiClosureDate-${flexiClosureDate?.index}-startDate`}
                        label="Início"
                        value={flexiClosureDate?.startDate}
                        minDate={getMinStartDate(flexiClosureDate?.index)}
                        maxDate={flexiClosureDate?.endDate}
                        placeholder="Selecione a data"
                        onChange={this.handleChange}
                        required
                        disabled={flexiClosureDate.timerExist}
                      />
                    </Form.Group>
  
                    {/* Fim do Período */}
                    <Form.Group as={Col} lg="2" xs="12">
                      <InputField
                        type="date"
                        name={`flexiClosureDate-${flexiClosureDate?.index}-endDate`}
                        label="Fim"
                        value={flexiClosureDate?.endDate}
                        minDate={flexiClosureDate?.startDate}
                        maxDate={getMaxStartDate(flexiClosureDate?.index)}
                        placeholder="Selecione a data"
                        onChange={this.handleChange}
                        required
                        disabled={flexiClosureDate.timerExist}
                      />
                    </Form.Group>
                    {
                      !flexiClosureDate.timerExist &&
                      <Button
                        variant="danger"
                        type="button"
                        onClick={() => this.handleRemoveFlexiClosureDate(flexiClosureDate.index)} 
                        style={{margin: '0 4px'}}
                      >
                        <span className="text-light">-</span>
                      </Button>
                    }
                  </Form.Row>
                )
              }
            </Form>
          </StyledCardBody>
        </Card>
        <FormActions
          onCancel={this.props.history.goBack}
          isEdit={isEditing}
          onCleanForm={this.onCleanForm}
          onSubmit={this.submit}
          formIsValid={this.state.client && this.state.project}
          hideReload
        />
      </>
    );
  }
}
