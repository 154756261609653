import React from "react";
import { Form, Col, Card } from "react-bootstrap";
import Swal from "sweetalert2";
import { InexApiService } from "../../../../services/InexApiService";
import FormActions from "../../components/FormActions";
import Loading from "../../../home/components/Loading";
import { InputField } from "../../components/InputField";
import { StyledCardBody } from "../../components/AdminCard/style";
import { AdminTitle } from "../../components/AdminTitle";
import IsValidName from "../../../../utils/validators/IsValidName";
import IsValidDescricao from "../../../../utils/validators/IsValidDescricao";
import { ActivityModal } from "./component/Modal";
import IsValidEmailAlocator from "../../../../utils/validators/IsValidEmailAlocator";
import formatCurrency from "../../../../utils/FormatCurrency";
import { ContabilModal } from "./component/ContabilModal";
import { MdEdit } from "react-icons/md";
import styled from "styled-components";
import { CycleList } from "../../../../utils/CycleList";

const initialState = {
  id: 0,
  nome: "",
  cliente: "",
  coordenador: "",
  gerenteComercial: "",
  areaPagadora: "",
  coordenacao: "",
  clientCoordinator: "",
  profissionais: [],
  proposal: "",
  clientManager: "",
  alocatorName: "",
  alocatorEmail: "",
  descricao: "",
  isLoading: false,
  modalOpen: false,
  contabilModalOpen: false,
  editAllocation: false,
  professionalEdit: "",
  persistedModel: "",
  idRFQ: "",
  rfqDefault: "",
  wageHourDefault: "",
  nomeProjeto: "",
  nomePersistent: "",
  scopeValue: "",
  closedDay: 31,
  projetado: 0,
  custo: 0,
  lucro: 0,
  flexiClosure: false,
  errors: { // Novo estado de erros
    proposal: false,
  },
};

const Button = styled.button`
  width: 100%;
  height: 100%;
  font-family: "Cera Pro Bold", sans-serif;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  text-transform: uppercase;
  background: #fff;
  margin-right: 8px;
  border-radius: 4px;
  color: ${({ color }) => (color ? color : "#4D4C4D")};
  border: ${({ color }) =>
    color ? `1px solid ${color}` : "1px solid #4D4C4D"};

  &:disabled {
    background: #807e80;
    border-color: ${({ color }) => (color ? "#807E80" : "#4D4C4D88")};
  }

  ${({ variant }) =>
    variant === "primary" &&
    `
    background: #4672C5;
    color: white;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    margin-right: 0px;
    `};
`;

export default class ClientForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
      coordinationOptions: [],
      employeesOptions: [],
      gerentesOptions: [],
      projectTypeOptions: [],
      proposalOptions: [],
      clientesOptions: [],
      coordinatorsOptions: [],

      payingAreaOptions: [],
      projectOptions: [],

      modalOptions: [
        { id: 1, name: "Teste 1" },
        { id: 2, name: "Teste 2" }
      ],
      isEditing: false
    };

    this.api = new InexApiService();

    this.handleChange = this.handleChange.bind(this);
    this.handleActivePayingArea = this.handleActivePayingArea.bind(this);
    this.handleAllocationChange = this.handleAllocationChange.bind(this);
    this.onCleanForm = this.onCleanForm.bind(this);
    this.loadProjeto = this.loadProjeto.bind(this);
    this.loadClientes = this.loadClientes.bind(this);
    this.loadEmployees = this.loadEmployees.bind(this);
    this.loadPayingArea = this.loadPayingArea.bind(this);
    this.loadProposals = this.loadProposals.bind(this);
    this.loadCoordination = this.loadCoordination.bind(this);
    this.loadProjectTypes = this.loadProjectTypes.bind(this);
    this.loadBusinessProposals = this.loadBusinessProposals.bind(this);
    this.submit = this.submit.bind(this);
    this.loadUserData = this.loadUserData.bind(this);
    this.handleChangeName = this.handleChangeName.bind(this);
    this.loadProjects = this.loadProjects.bind(this);
  }

  async componentDidMount() {
    const isEditing = !!this.props.match.params.id;
    this.setState({ isLoading: true, isEditing });
    try {
      const reqs = [
        this.loadClientes(),
        this.loadProjectTypes()
      ];

      if (this.props.match.params.id) {
        reqs.push(this.loadProjeto(this.props.match.params.id));
      } else {
        this.loadProjects()
      }

      await Promise.all(reqs);
    } catch (error) {
      console.log(error)
      Swal.fire(
        "Erro!",
        error?.response ? error?.response?.data : "Ocorreu um erro ao carregar os dados, tente novamente mais tarde!",
        "error"
      );
    }

    this.setState({ isLoading: false });
  }

  loadProjeto = async id => {
    console.log("Id: " + id);
    const project = await this.api.makeHttpRequest({
      url: `/project/${id}`
    });

    console.log("Projetos", project)

    if (project.client.idClient) {
      this.loadEmployees(project.client.idClient);
      this.loadCoordination(project.client.idClient);
      this.loadPayingArea(project.projectArea.idProjectArea)
      this.loadBusinessProposals([project.client.idClient], id)
    }

    const usersFormatted =
      project.professionals?.length > 0
        ? project.professionals.map(item => ({
            label: project.projectType.name.toUpperCase() === 'FÁBRICA' ? `${item.name.split('(')[0].trim()} (Qtd Hrs: ${item.quantityHour}/${item.quantityHourProject} | R$ Hrs: ${item.wageHour}/${item.wageHourProject})` : item.name,
            value: item.idUser,
            activateDate: item.activateDate,
            idBusinessProposalValue: item.idBusinessProposalValue,
            activity: item.businessProposalValue.profileDescription,
            wageHour: item.wageHour ? formatCurrency(item.wageHour.toFixed(2)) : "",
            wageHourProject: item.wageHourProject ? formatCurrency(item.wageHourProject.toFixed(2)) : "",
            quantityHour: item.quantityHour,
            quantityHourProject: item.quantityHourProject
          }))
        : [];

    if (project.projectType.name.toUpperCase() === 'FÁBRICA') {
      const projetado = usersFormatted.reduce((acc, p) => acc + (p.quantityHourProject * parseFloat(p.wageHourProject.replaceAll('.', '').replaceAll(',', '.'))), 0);
      const custo = usersFormatted.reduce((acc, p) => acc + (p.quantityHour * parseFloat(p.wageHour.replaceAll('.', '').replaceAll(',', '.'))), 0);
      const lucro = projetado - custo;

      this.setState({
        projetado: projetado,
        custo: custo,
        lucro: lucro
      });
    }

    const currentClient = {
      label: project.client.name,
      value: project.client.idClient,
      isDisabled: project.client.deletionDate !== null ?? false,
    }

    const currentCoordination = {
      label: project.projectArea.name,
      value: project.projectArea.idProjectArea,
      isDisabled: project.projectArea.deletionDate !== null ?? false,
    }

    const currentPayingArea = {
      label: project.payingArea.name,
      value: project.payingArea.idPayingArea,
      isDisabled: project.payingArea.deletionDate !== null ?? false,
    }

    const currentProjectType = {
      label: project.projectType.name,
      value: project.projectType.idProjectType,
      isDisabled: project.projectType.deletionDate !== null ?? false,
    }

    // Client
    if(project.client.deletionDate !== null) {
      this.setState({ clientesOptions: [...this.state.clientesOptions, currentClient] })
    }

    // Coordination
    if(project.projectArea.deletionDate !== null) {
      this.setState({ coordinationOptions: [...this.state.coordinationOptions, currentCoordination] })
    }

    // PayingArea
    if(project.payingArea.deletionDate !== null) {
      this.setState({ payingAreaOptions: [...this.state.payingAreaOptions, currentPayingArea] })
    }

    // ProjetType
    this.setState({ projectType: currentProjectType })

    const currentProposal = project.businessProposal ? {
      label: project.businessProposal.name,
      value: project.businessProposal.idBusinessProposal,
      isDisabled: project.businessProposal.deletionDate !== null ?? false,
    } : ""

    if(project.businessProposal && project.businessProposal.deletionDate !== null) {
      this.loadProposals(project.businessProposal.idBusinessProposal)
      this.setState({ proposalOptions: [...this.state.proposalOptions, currentProposal] })
    }

    const persistedModel = {
      id: id,
      nome: { label: project.name, value: project.name },
      projetoNome: project.name,
      nomePersistent: project.name,
      cliente: currentClient,
      coordenador: project.coordinator.idUser,
      gerenteComercial: project.commercial.idUser,
      coordenacao: currentCoordination,
      descricao: project.description,
      clientCoordinator: project.clientCoordinator,
      clientManager: project.clientProjectManager,
      alocatorName: project.alocatorName,
      alocatorEmail: project.alocatorEmail,
      profissionais: usersFormatted,
      areaPagadora: currentPayingArea,
      scopeValue: project.scopeValue ? formatCurrency(project.scopeValue.toFixed(2)) : "",
      proposal: currentProposal,
      closedDay: project.closedDay,
      flexiClosure: project.flexiClosure
    };

    this.loadProjects(project.name)

    const persistedModelJson = JSON.stringify(persistedModel);
    this.setState({
      ...persistedModel,
      isLoading: false,
      persistedModel: persistedModelJson
    });
  };

  loadClientes = async () => {
    const clientes = await this.api.makeHttpRequest({
      url: "/project/selects"
    });

    const clientOptions =
      clientes.clients?.length > 0
        ? clientes.clients.map(item => ({
            label: item.name,
            value: item.idClient,
            isDisabled: false
          }))
        : [];

    this.setState({
      clientesOptions: clientOptions,
      gerentesOptions: clientes.commercialManagers,
      coordinatorsOptions: clientes.coordinators
    });
  };

  loadCoordination = async idClient => {
    this.setState({ isLoading: true });
    const coordinations = await this.api.makeHttpRequest({
      url: `/project/coordinations-by-client/${idClient}`
    });

    const coordinationOptions =
      coordinations.coordinations.length > 0
        ? coordinations.coordinations.map(item => ({
            label: item.name,
            value: item.idProjectArea,
            isDisabled: false
          }))
        : [];

    this.setState({
      coordinationOptions,
      isLoading: false
    });
  };

  loadProjectTypes = async () => {
    const projectTypes = await this.api.makeHttpRequest({
      url: "/projecttype/select"
    });

    const projectTypeOptions =
      projectTypes.length > 0
        ? projectTypes.map(item => ({
            label: item.name,
            value: item.idProjectType,
            isDisabled: false
          }))
        : [];

    this.setState({
      projectTypeOptions: projectTypeOptions,
      projectType: projectTypeOptions[0]
    });
  }

  loadBusinessProposals = async (idClients, idProject) => {
    let idClientsQuery = idClients ? "?" + idClients.map((id) => 'idClients='+id).join('&') : ""
    let endpoint = `/businessproposal/select-by-clients${idClientsQuery}`;
    const proposals = await this.api.makeHttpRequest({
      url: endpoint
    });

    const proposalOptions =
      proposals.length > 0
        ? proposals.map(item => ({
            label: item.name,
            value: item.idBusinessProposal,
            isDisabled: false
          }))
        : [];

    if (!idProject) {
      this.loadProposals(proposalOptions[0].value);
      this.setState({
        proposalOptions: proposalOptions,
        proposal: proposalOptions[0]
      });
    } else {
      this.setState({
        proposalOptions: proposalOptions
      });
    }
  }

  // Área Pagadora
  loadPayingArea = async idCoordination => {
    this.setState({ isLoading: true });
    const payingAreas = await this.api.makeHttpRequest({
      url: `/project/paying-area-by-coordination/${idCoordination}`
    });

    const payingAreaOptions =
      payingAreas?.length > 0
        ? payingAreas.map(item => ({
            label: item.name,
            value: item.idPayingArea,
            isDisabled: false
          }))
        : [];

    if (payingAreaOptions?.length <= 0) {
      Swal.fire(
        "Aviso!",
        "Não existem Áreas Pagadoras para esta Coordenação.",
        "warning"
      );
    }

    this.setState({
      payingAreaOptions,
      isLoading: false
    })
  }

  loadProjects = async (projectName) => {
    let params = {
      filterString: projectName
    }

    const response = await this.api.makeHttpRequest({
      url: `/project/search`,
      params
    });

    const projectOptions =
      response.data?.length > 0
        ? response.data.map(item => ({
            label: item.name,
            value: item.idProject,
            isDisabled: false
          }))
        : [];

    this.setState({
      projectOptions
    })
  }

  //Alocações
  loadEmployees = async idClient => {
    this.setState({ isLoading: true });
    const idProject = this.props.match.params.id;

    const employees = await this.api.makeHttpRequest({
      url: !idProject
        ? `/project/client-professionals/${idClient}`
        : `/project/client-professionals/${idClient}/${idProject}`
    });

    const employeesOptions =
      employees?.length > 0
        ? employees.map(item => ({
            label: item.name,
            value: item.idUser,
            idBusinessProposalValue: item.idBusinessProposalValue ?? null
          }))
        : [];

    if (employeesOptions?.length <= 0) {
      Swal.fire(
        "Aviso!",
        "Não existem profissionais alocados a este cliente.",
        "warning"
      );
    }

    this.setState({
      employeesOptions,
      isLoading: false
    });
  };

  loadProposals = async (idBusinessProposal) => {
    let proposals = []
    if (idBusinessProposal) {
      proposals = await this.api.makeHttpRequest({
        url: "/businessproposal/values/" + idBusinessProposal
      });
    } else {
      proposals = await this.api.makeHttpRequest({
        url: "/project/all-rfq"
      });
    }

    const formattedProposal =
      proposals.length > 0
        ? proposals.map(prop => ({
            value: prop.idBusinessProposalValue,
            label: prop.profileDescription
          }))
        : [];

    this.setState({
      modalOptions: formattedProposal
    });
  };

  submit = async e => {
    e.preventDefault();
    const id = this.props.match.params.id;
    this.setState({ isLoading: true });
    try {
      const reqBase = id
        ? { method: "PUT", url: `/project/update/${id}` }
        : { method: "POST", url: "/project/save" };

      const unFormattedUsers =
        this.state.profissionais?.length > 0
          ? this.state.profissionais.map(item => ({
              IdUser: item.value,
              idBusinessProposalValue: Number(item.idBusinessProposalValue) ?? null,
              activateDate: item.activateDate,
              wageHour: item.wageHour ? parseFloat(item.wageHour.replaceAll('.', '').replaceAll(',', '.')) : null,
              quantityHour: item.quantityHour,
              wageHourProject: item.wageHourProject ? parseFloat(item.wageHourProject.replaceAll('.', '').replaceAll(',', '.')) : null,
              quantityHourProject: item.quantityHourProject
            }))
          : [];

      await this.api.makeHttpRequest({
        ...reqBase,
        data: {
          name: this.state.projetoNome,
          description: this.state.descricao,
          idClient: this.state.cliente.value,
          idCoordinator: this.state.coordenador,
          idCommercial: this.state.gerenteComercial,
          idPayingArea: this.state.areaPagadora.value,
          clientCoordinator: this.state.clientCoordinator,
          clientProjectManager: this.state.clientManager,
          alocatorName: this.state.alocatorName,
          alocatorEmail: this.state.alocatorEmail,
          idProjectArea: this.state.coordenacao.value,
          idProjectType: this.state.projectType.value,
          idBusinessProposal: this.state.proposal.value,
          scopeValue: this.state.projectType.label.toUpperCase() === 'SQUAD' ? parseFloat(this.state.scopeValue.replaceAll('.', '').replaceAll(',', '.')) : null,
          professionals: unFormattedUsers,
          closedDay: this.state.closedDay,
          flexiClosure: this.state.flexiClosure
        }
      });

      this.afterSubmit();
    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data
          ? e.response.data
          : "Erro ao cadastrar projeto, tente novamente mais tarde.",
        "error"
      );
    }

    this.setState({ isLoading: false });
  };

  afterSubmit = async () => {
    this.setState({
      ...initialState,
      resetInput: true
    });

    const { isEditing } = this.state;

    const { value } = await Swal.fire({
      title: "Sucesso!",
      text: "Dados salvos com sucesso.",
      icon: "success",
      showCancelButton: !isEditing,
      confirmButtonText: isEditing ? "Ok" : "Novo cadastro",
      cancelButtonText: "Sair"
    });

    if (!value || isEditing) {
      this.props.history.push("/admin/projetos");
    }
  };

  handleChangeName = (value, event) => {
    if (event.action === 'input-change') {
      if (this.state.id !== 0 && value === '') {
        this.setState({ nome: "" });
      }
      this.loadProjects(value)
      return this.setState({ projetoNome: value });
    }
  };

  handleChange = (e, select2Event) => {
    if (select2Event?.name === "cliente") {
      this.setState({
        profissionais: [],
        coordenacao: "",
        areaPagadora: ""
      });

      if (e?.value) {
        this.loadEmployees(e.value);
        this.loadCoordination(e.value);
        this.loadBusinessProposals([e.value]);
      } else {
        this.setState({
          profissionais: [],
          employeesOptions: []
        });
      }

      return this.setState({ cliente: e });
    }

    if (select2Event?.name === "coordenacao") {
      this.setState({ areaPagadora: "" });
      if(e?.value) {
        this.loadPayingArea(e.value);
      }

      return this.setState({ coordenacao: e })
    }

    if(select2Event?.name === "areaPagadora") {
      return this.setState({ areaPagadora: e });
    }

    if(select2Event?.name === "tipoDeProjeto") {
      const employees = this.state.employeesOptions.map((employee) => {
        employee.label = employee.label.split('(')[0].trim()
        return employee
      })
      return this.setState({ projectType: e, profissionais: [], employeesOptions: employees });
    }

    if (select2Event?.name === "proposal") {
      this.loadProposals(e.value);
      return this.setState({ proposal: e })
    }

    if(select2Event?.name === "nome") {
      return this.setState({ nome: e });
    }

    const { name, value } = e?.target;

    let valueFormated = e.target.value
    if (name === "scopeValue") {
      valueFormated = formatCurrency(value)
    }

    this.setState({
      [name]: valueFormated
    });
  };

  handleActivePayingArea = e => {
    if (e?.value && e?.label) {
      this.setState({ coordenacao: e });
      this.loadPayingArea(e.value);
    } else {
      this.setState({
        payingAreaOptions: []
      });
    }

    this.setState({ coordenacao: e })
  }

  handleAllocationChange = (value, event) => {
    if (!this.state.proposal) {
      this.setState({
        errors: { ...this.state.errors, proposal: true } // Marca erro no campo "Propostas"
      });
      return; // Interrompe o fluxo se `proposal` estiver vazio
    }

    if (event.action === "select-option") {
      this.setState({ idRFQ: event?.option?.idBusinessProposalValue ?? '', modalOpen: true });
    }
    const array1 = value;
    const array2 = this.state.profissionais;
    const set1 = new Set(array1);
    const set2 = new Set(array2);
    const difference = [...set1].filter(x => !set2.has(x));
    const uniqueElement = difference.length > 0 ? difference[0] : null;
    if (uniqueElement) {
      const idUser = uniqueElement.value
      this.loadUserData(idUser)
    }

    const profissionalIds = Array.isArray(value)
    ? value.map(({ value }) => value)
    : [];

    const employeesOptions = this.state.employeesOptions.map((e) => {
      const { value, label } = e;

      if (!profissionalIds.includes(value)) {
        e.label = label.split('(')[0].trim();
      }

      return e;
    });
    this.setState({ profissionais: value, employeesOptions });
  };

  handleEditAllocation = (data) => {
    this.setState({ modalOpen: true, editAllocation: true, professionalEdit: data });
  };

  handleDeleteAllocation = (data) => {
    const employeesOptions = this.state.employeesOptions.map(e => {
      if (e.value === data.value) {
        e.label =  e.label.split('(')[0].trim()
      }
      return e
    })
    const profissionais = this.state.profissionais.map(p => {
      if (p.value !== data.value) {
        return p
      }
    })
    this.setState({ employeesOptions, profissionais });
  };

  loadUserData = async (idUser) => {
    const user = await this.api.makeHttpRequest({
      url: `/professional/${idUser}`
    });
    if (user) {
      if (this.state.modalOptions.find((o) => o.value === user.idRFQ)) {
        this.setState({
          wageHourDefault: user.wageHourDefault ? formatCurrency(user.wageHourDefault.toFixed(2)) : null,
          rfqDefault: {
            label: user.rfqDescription,
            value: user.idRFQ
          }
        });
      } else {
        this.setState({
          wageHourDefault: user.wageHourDefault ? formatCurrency(user.wageHourDefault.toFixed(2)) : null
        });
      }
    }
  }

  handleProfessionalActivity = (activity, cancel = false, activateDate = null, wageHour = null, quantityHour = null, wageHourProject = null, quantityHourProject = null) => {
    const { profissionais, editAllocation, professionalEdit, employeesOptions, proposal } = this.state;

    // Validação do campo "proposal"
    if (!proposal) {
      this.setState({
          errors: { ...this.state.errors, proposal: true } // Marca erro no campo "Propostas"
      });
      return; // Interrompe o fluxo se a proposta não estiver preenchida
    }

    // Limpa o erro caso esteja preenchido
    this.setState({
      errors: { ...this.state.errors, proposal: false }
    });


    let arr;
    if (!editAllocation) {
      arr = [...profissionais];
      const lastItem = arr.pop();

      if (cancel) {
        this.setState({ profissionais: arr,  idRFQ: '' });
        return;
      }

      lastItem.idBusinessProposalValue = activity.value;
      lastItem.activateDate = activateDate;
      if (this.state.projectType?.label.toUpperCase().toUpperCase() === 'FÁBRICA') {
        lastItem.label = `${lastItem.label.split('(')[0].trim()} (Qtd Hrs: ${quantityHour}/${quantityHourProject} | R$ Hrs: ${wageHour}/${wageHourProject})`
        lastItem.activity = activity.label;
        lastItem.quantityHour = quantityHour;
        lastItem.wageHourProject = wageHourProject;
        lastItem.quantityHourProject = quantityHourProject;
      }
      lastItem.wageHour = wageHour;
      arr.push(lastItem);
    } else {
      arr = profissionais.map((profissional) => {
        if (profissional.value === professionalEdit.value) {
          profissional.idBusinessProposalValue = activity.value;
          profissional.activateDate = activateDate;
          profissional.wageHour = wageHour;
          if (this.state.projectType?.label.toUpperCase().toUpperCase() === 'FÁBRICA') {
            profissional.label = `${profissional.label.split('(')[0].trim()} (Qtd Hrs: ${quantityHour}/${quantityHourProject} | R$ Hrs: ${wageHour}/${wageHourProject})`
            profissional.activity = activity.label;
            profissional.quantityHour = quantityHour;
            profissional.wageHourProject = wageHourProject;
            profissional.quantityHourProject = quantityHourProject;
          }
        }
        return profissional
      })
    }

    if (this.state.projectType?.label.toUpperCase() !== 'FÁBRICA') {
      this.setState({ profissionais: arr, idRFQ: '' });
    } else {
      const projetado = arr.reduce((acc, p) => acc + (p.quantityHourProject * p.wageHourProject), 0);
      const custo = arr.reduce((acc, p) => acc + (p.quantityHour * p.wageHour), 0);
      const lucro = projetado - custo;

      this.setState({
        profissionais: arr,
        idRFQ: '',
        projetado: projetado,
        custo: custo,
        lucro: lucro
      });
    }
  };

  formIsValid = () => {
    const { isEditing, persistedModel } = this.state;

    const currentModel = {
      id: this.state.id,
      nome: this.state.projetoNome,
      cliente: this.state.cliente,
      coordenador: this.state.coordenador,
      gerenteComercial: this.state.gerenteComercial,
      coordenacao: this.state.coordenacao,
      clientCoordinator: this.state.clientCoordinator,
      clientManager: this.state.clientManager,
      alocatorName: this.state.alocatorName,
      alocatorEmail: this.state.alocatorEmail,
      profissionais: this.state.profissionais,
      areaPagadora: this.state.areaPagadora
    };

    const currentModelJson = JSON.stringify(currentModel);

    let nameVerified = (this.state.id === 0 && this.state.projectOptions.some(project => project.label === this.state.projetoNome)) ||
    (this.state.id !== 0 && this.state.projetoNome !== this.state.nomePersistent && this.state.projectOptions.some(project => project.label === this.state.projetoNome))

    let scopeVerified = (this.state.projectType?.label.toUpperCase() === 'SQUAD' ? (parseFloat(this.state.scopeValue.replaceAll('.', '').replaceAll(',', '.')) > 0 ? true : false) : true)

    return (
      scopeVerified &&
      !nameVerified &&
      this.state.projetoNome &&
      IsValidName(this.state.projetoNome) &&
      this.state.cliente &&
      this.state.coordenador &&
      this.state.coordenacao &&
      this.state.areaPagadora &&
      this.state.gerenteComercial &&
      this.state.clientCoordinator &&
      IsValidName(this.state.clientCoordinator) &&
      this.state.clientManager &&
      IsValidName(this.state.clientManager) &&
      IsValidName(this.state.alocatorName) &&
      IsValidEmailAlocator(this.state.alocatorEmail)
    );
  };

  onCleanForm = async () => {
    if (this.props.match.params.id) {
      this.setState({ isLoading: true });

      try {
        await this.loadClient(this.props.match.params.id);
      } catch (e) {
        Swal.fire(
          "Erro",
          "Problema ao reverter as alterações, tente mais tarde",
          "error"
        );
      }

      this.setState({ isLoading: false });
    } else {
      this.setState({
        ...initialState,
        resetInput: true
      });
    }
  };

  handleContabilModal = (cancel = false, profissionais = null) => {
    if (profissionais) {
      let arr = profissionais.map((p) => {
        p.label = `${p.label.split('(')[0].trim()} (Qtd Hrs: ${p.quantityHour}/${p.quantityHourProject} | R$ Hrs: ${p.wageHour}/${p.wageHourProject})`
        return p
      })

      const projetado = profissionais.reduce((acc, p) => acc + (p.quantityHourProject * p.wageHourProject), 0);
      const custo = profissionais.reduce((acc, p) => acc + (p.quantityHour * p.wageHour), 0);
      const lucro = projetado - custo;

      this.setState({
        profissionais: arr,
        projetado,
        custo,
        lucro
      })
    }
  }

  render() {
    if (this.state.isEditing) {
      window.setPageTitle("Atualizar Projeto - Admin");
    } else {
      window.setPageTitle("Cadastro de Projeto - Admin");
    }

    const {
      nome,
      cliente,
      coordenador,
      gerenteComercial,
      projectType,
      areaPagadora,
      payingAreaOptions,
      coordenacao,
      profissionais,
      descricao,
      clientCoordinator,
      clientManager,
      alocatorName,
      alocatorEmail,
      isLoading,
      employeesOptions,
      clientesOptions,
      coordinatorsOptions,
      gerentesOptions,
      projectTypeOptions,
      proposal,
      proposalOptions,
      coordinationOptions,
      isEditing,
      rfqDefault,
      wageHourDefault,
      projetoNome,
      projectOptions,
      scopeValue,
      closedDay,
      projetado,
      custo,
      lucro,
      contabilModalOpen,
      flexiClosure
    } = this.state;

    return (
      <>
        <Loading isLoading={isLoading} />
        <AdminTitle title="Dados do Projeto" disableHeader />
        <Card className="mt-4">
          <StyledCardBody>
            <Form style={{ flex: "1" }}>
              {/* row 1 */}
              <Form.Row>
                {/* Cliente */}
                <Form.Group as={Col} lg="4" xs="12">
                  <InputField
                    as="select2"
                    name="cliente"
                    label="Cliente"
                    value={cliente}
                    onChange={this.handleChange}
                    placeholder="Selecione o cliente"
                    options={clientesOptions}
                    closeMenuOnSelect={true}
                    required
                  />
                </Form.Group>

                {/* Coordenação */}
                <Form.Group as={Col} lg="4" xs="12">
                  <InputField
                    as="select2"
                    name="coordenacao"
                    label="Coordenação"
                    value={coordenacao}
                    onChange={this.handleChange}
                    placeholder="Selecione a coordenação"
                    options={coordinationOptions}
                    closeMenuOnSelect={true}
                    disabled={cliente?.length <= 0 ? true : false}
                    required
                  />
                </Form.Group>

                {/* Área pagadora */}
                <Form.Group as={Col} lg="4" xs="12">
                  <InputField
                    as="select2"
                    name="areaPagadora"
                    label="Área Pagadora"
                    value={areaPagadora}
                    onChange={this.handleChange}
                    disabled={coordenacao?.length <= 0 ? true : false}
                    options={payingAreaOptions}
                    closeMenuOnSelect={true}
                    placeholder="Selecione a área pagadora"
                    required
                  />
                </Form.Group>
              </Form.Row>

              {/* row 2 */}
              <Form.Row>
                {/* Projeto / Squad */}
                <Form.Group as={Col} lg="4" xs="12">
                  <InputField
                    as="text2"
                    type="text"
                    name="nome"
                    label="Projeto / Squad"
                    placeholder="Digite a Projeto / Squad"
                    value={nome}
                    textValue={projetoNome}
                    options={projectOptions}
                    onChangeInput={this.handleChangeName}
                    isInvalid={nome && !IsValidName(nome)}
                    required
                  />
                </Form.Group>

                {/* Fechamento Flexível */}
                <Form.Group as={Col} lg="2" xs="12">
                  <InputField
                    as="select"
                    name="flexiClosure"
                    label="Fechamento Flexível"
                    value={flexiClosure}
                    onChange={this.handleChange}
                    required
                  >
                      <option key={'Não'} value={false}>
                        Não
                      </option>
                      <option key={'Sim'} value={true}>
                        Sim
                      </option>
                  </InputField>
                </Form.Group>

                {/* Cordenador de Operação */}
                <Form.Group as={Col} lg="3" xs="12">
                  <InputField
                    as="select"
                    name="coordenador"
                    label="Coordenador de Operação"
                    value={coordenador}
                    onChange={this.handleChange}
                    required
                  >
                    <option value="">
                      Selecione o coordenador de operação
                    </option>
                    {coordinatorsOptions?.length > 0 &&
                      coordinatorsOptions.map(p => (
                        <option key={p.idUser} value={p.idUser}>
                          {p.name}
                        </option>
                      ))}
                  </InputField>
                </Form.Group>

                {/* Gerente Comercial */}
                <Form.Group as={Col} lg="3" xs="12">
                  <InputField
                    as="select"
                    name="gerenteComercial"
                    label="Gerente Comercial"
                    value={gerenteComercial}
                    onChange={this.handleChange}
                    required
                  >
                    <option value="">Selecione o gerente comercial</option>
                    {gerentesOptions?.length > 0 &&
                      gerentesOptions.map(p => (
                        <option key={p.idUser} value={p.idUser}>
                          {p.name}
                        </option>
                      ))}
                  </InputField>
                </Form.Group>
              </Form.Row>

              {/* row 3 */}
              <Form.Row style={{ height: "fit-content" }}>
                {/* Alocações */}
                <Form.Group as={Col} lg='6' xs="12">
                  <div style={{ display: 'flex', justifyContent: 'space-between', gap: '12px' }}>
                    <div style={{width: '50%'}}>
                      <InputField
                        as="select2"
                        name="tipoDeProjeto"
                        label="Tipo de Projeto"
                        value={projectType}
                        onChange={this.handleChange}
                        placeholder="Selecione o tipo de projeto"
                        options={projectTypeOptions}
                        // adicionando o closeMenuOnSelect para poder fechar ao clicar o menu de tipo de projeto
                        closeMenuOnSelect={true}
                        required
                      />
                    </div>
                    <div style={{width: '50%'}}>
                      <InputField
                        as="select2"
                        name="proposal"
                        label="Propostas"
                        value={proposal}
                        onChange={this.handleChange}
                        placeholder="Selecione a proposta"
                        options={proposalOptions}
                        closeMenuOnSelect={true}
                        disabled={!cliente}
                        required
                        className={this.state.errors.proposal ? "error" : ""} // Adiciona classe "error" se houver erro
                      />
                        {this.state.errors.proposal && (
                          <span style={{ color: "red", fontSize: "12px" }}>
                            Por favor, selecione uma proposta.
                          </span>
                        )}

                    </div>
                    {
                      projectType?.label.toUpperCase() === 'SQUAD' && <div style={{width: '34%'}}>
                        <InputField
                          type="text"
                          name="scopeValue"
                          label="Valor Escopo"
                          value={scopeValue}
                          placeholder="Digite o valor do escopo"
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                    }
                    {
                      projectType?.label.toUpperCase() === 'FÁBRICA' &&
                      <>
                        <div style={{width: '34%'}}>
                          <InputField
                            as="select"
                            name="closedDay"
                            label="Dia do Fechamento"
                            value={closedDay}
                            onChange={this.handleChange}
                            required
                          >
                            <option value="">Selecione a data</option>
                            {CycleList?.length > 0 &&
                              CycleList.map((date, index) => (
                                <option key={index} value={date.value}>
                                  {date.name}
                                </option>
                              )
                            )}
                          </InputField>
                        </div>
                      </>
                    }
                  </div>
                  <br/>
                  {
                    projectType?.label?.toUpperCase() === 'FÁBRICA' ?
                    <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                      <div style={{width: '90%'}}>
                        <InputField
                          isMulti
                          as="select2"
                          name="profissionais"
                          label="Alocações"
                          value={profissionais}
                          onChange={this.handleAllocationChange}
                          onEdit={this.handleEditAllocation}
                          onDelete={this.handleDeleteAllocation}
                          placeholder="Selecione os profissionais"
                          options={employeesOptions}
                          disabled={!cliente || !proposal}
                          editable={true}
                          hasError={!cliente || !proposal} // Passa a condição de erro
                        />
                      </div>
                      <div style={{width: '10%'}}>
                        <Button
                          variant="primary"
                          type="button"
                          color="#4672C5"
                          onClick={() => this.setState({contabilModalOpen: true})}
                        >
                          <MdEdit />
                        </Button>
                      </div>
                      {!cliente || !proposal ? (
                        <span style={{ color: 'red', fontSize: '12px' }}>
                          Cliente ou proposta não selecionado.
                        </span>
                      ) : null}
                    </div> :
                     <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    <InputField
                      isMulti
                      as="select2"
                      name="profissionais"
                      label="Alocações"
                      value={profissionais}
                      onChange={this.handleAllocationChange}
                      onEdit={this.handleEditAllocation}
                      onDelete={this.handleDeleteAllocation}
                      placeholder="Selecione os profissionais"
                      options={employeesOptions}
                      disabled={!cliente || !proposal}
                      editable={true}
                      hasError={!cliente || !proposal} // Passa a condição de erro
                    />
                    {!cliente || !proposal ? (
                      <span style={{ color: 'red', fontSize: '12px' }}>
                        Cliente ou proposta não selecionado.
                      </span>
                    ) : null}
                  </div>
                  }

                </Form.Group>

                {/* Descrição */}
                <Form.Group as={Col} lg="6" xs="12">
                  <InputField
                    as="textarea"
                    name="descricao"
                    label="Descrição"
                    placeholder="Descreva o projeto"
                    style={{ height: "115px" }}
                    value={descricao}
                    onChange={this.handleChange}
                  />
                </Form.Group>
              </Form.Row>
            </Form>
          </StyledCardBody>
        </Card>
        <Card className="mt-4">
          <StyledCardBody>
            <Form className="form-admin" style={{ flex: "1" }}>
              {/* row 1 */}
              <h1 className="card-title">Ponto Focal no Cliente </h1>
              <Form.Row>
                {/* Coordenador - Cliente */}
                <Form.Group as={Col} lg="6" xs="12">
                  <InputField
                    type="text"
                    name="clientCoordinator"
                    label="Coordenador"
                    placeholder="Digite o nome do coordenador"
                    value={clientCoordinator}
                    onChange={this.handleChange}
                    isInvalid={
                      clientCoordinator && !IsValidName(clientCoordinator)
                    }
                    required
                  />
                </Form.Group>

                {/* Gerente - Cliente */}
                <Form.Group as={Col} lg="6" xs="12">
                  <InputField
                    type="text"
                    name="clientManager"
                    label="Gerente do Projeto (SM)"
                    placeholder="Digite o nome do gerente"
                    value={clientManager}
                    onChange={this.handleChange}
                    isInvalid={clientManager && !IsValidName(clientManager)}
                    required
                  />
                </Form.Group>
              </Form.Row>
            </Form>
          </StyledCardBody>
        </Card>
        <Card className="mt-4">
          <StyledCardBody>
            <Form className="form-admin" style={{ flex: "1" }}>
              {/* row 1 */}
              <h1 className="card-title">Alocador de Horas</h1>
              <Form.Row>
                {/* Nome do Alocador */}
                <Form.Group as={Col} lg="6" xs="12">
                  <InputField
                    type="text"
                    name="alocatorName"
                    label="Nome do Alocador"
                    placeholder="Digite o nome do alocador"
                    value={alocatorName}
                    onChange={this.handleChange}
                    isInvalid={
                      alocatorName && !IsValidName && !IsValidEmailAlocator(alocatorName)
                    }
                    required
                  />
                </Form.Group>

                {/* Email - Alocador */}
                <Form.Group as={Col} lg="6" xs="12">
                  <InputField
                    type="text"
                    name="alocatorEmail"
                    label='E-mails - (Separe por ";")'
                    placeholder="E-mail do alocador e quem deverá receber cópia."
                    value={alocatorEmail}
                    onChange={this.handleChange}
                    isInvalid={alocatorEmail && !IsValidEmailAlocator (alocatorEmail)}
                    required
                  />
                </Form.Group>
              </Form.Row>
            </Form>
          </StyledCardBody>
        </Card>
        {
          projectType?.label.toUpperCase() === 'FÁBRICA' ?
            <div style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              flexWrap: 'nowrap',
              gap: '50px'
            }}>
              <div style={{marginTop: '24px'}}>
                <span style={{marginRight: '12px', fontSize: '20px'}}><b>Projetado: R$ {formatCurrency((projetado*100).toString())}</b></span>
                <span style={{marginRight: '12px', fontSize: '20px'}}><b>Custo: R$ {formatCurrency((custo*100).toString())}</b></span>
                <span style={{fontSize: '20px', color: `${lucro <= 0 ? 'red' : 'green'}`}}><b>Lucro: R$ {lucro < 0 ? '-' : ''}{formatCurrency(lucro <= 0? (lucro*100).toString() : (lucro*100).toString())}</b></span>
              </div>
              <FormActions
                onCancel={this.props.history.goBack}
                isEdit={isEditing}
                formIsValid={this.formIsValid()}
                onCleanForm={this.onCleanForm}
                onSubmit={this.submit}
                hideReload
              />
            </div>
          :
            <FormActions
              onCancel={this.props.history.goBack}
              isEdit={isEditing}
              formIsValid={this.formIsValid()}
              onCleanForm={this.onCleanForm}
              onSubmit={this.submit}
              hideReload
            />
        }
        <ActivityModal
          open={this.state.modalOpen}
          edit={this.state.editAllocation}
          professionalEdit={this.state.professionalEdit}
          close={() => this.setState({
            modalOpen: false,
            editAllocation: false,
            professionalEdit: ""
          })}
          options={this.state.modalOptions}
          idRFQ={this.state.idRFQ}
          handleConfirm={this.handleProfessionalActivity}
          rfqDefault={this.state.rfqDefault}
          wageHourDefault={this.state.wageHourDefault}
          projectType={this.state.projectType?.label.toUpperCase()}
        />
        {
          this.state.profissionais?.length > 0 &&
          <ContabilModal
            open={contabilModalOpen}
            close={() => this.setState({ contabilModalOpen: false })}
            handleConfirm={this.handleContabilModal}
            profissionalList={this.state.profissionais} />
        }
      </>
    );
  }
}
