import React, { useEffect, useState } from "react";
import { InputField } from "../../../../components/InputField";
import { Container } from "./style";
import moment from "moment";
import { ButtonFilter as Button } from "../Button/style";
import { FiFilter } from "react-icons/fi";
import { debounce } from "lodash";
import { getPortoDefaultDate } from "../../../../../../utils/GetPortoDefaultDate";
import { GetYearList, GetMonthList, getDefaultDate } from "../../../../../../utils/GetYearsAndMonths";
import { useSelector } from "react-redux";
import { ERoles } from "../../../../../../enums/Roles";
import { InexApiService } from "../../../../../../services/InexApiService";
import Swal from "sweetalert2";

const years = GetYearList()
const months = GetMonthList()

export const Filters = ({ reload, onSubmit, setReload, filterOptions, loading, setLoading, client, project, flexiClosureDates, setFlexiClosureDates }) => {
  const api = new InexApiService();
  const user = useSelector(state => state.auth.user);
  const isCoordinator = user?.role?.type === ERoles.OPERATION;
  const [projectOptions, setProjectOptions] = useState([]);
  const [professionalOptions, setProfessionalOptions] = useState([]);

  const [filters, setFilters] = useState({
    periodYear: moment().year(),
    periodMonth: moment().month()+1,
    startPeriod: user.clientDefault ? moment(getPortoDefaultDate(user.clientDefault.initialCycleDay).startDate).toDate() : undefined,
    endPeriod: user.clientDefault ? moment(getPortoDefaultDate(user.clientDefault.initialCycleDay).endDate).toDate() : undefined,
    client: user.clientDefault ? user.clientDefault.idClient : "",
    initialCycleDay: user.clientDefault ? user.clientDefault.initialCycleDay : "",
    project: "",
    coordinator: isCoordinator ? `${user.idUser}` : "",
    employee: ""
  });

  React.useEffect(() => {
    if (client !== null && project !== null) {
      setFilters({
        ...filters,
        client: client.idClient.toString(),
        project: project.idProject.toString(),
        coordinator: isCoordinator ? `${user.idUser}` : (project.coordinator ? project.coordinator.idUser : ""),
      })
    }
  }, [client, project])

  React.useEffect(() => {
    if (filters.client) {
      loadCoordinationByIdClient(filters.client);
      loadFlexiClosureDates(filters.client, undefined, filters.periodMonth, filters.periodYear);
      if (filterOptions.clients){
        const result = filterOptions.clients.find((c) => c.idClient.toString() === filters.client)
        if (result) {
          const period = getPortoDefaultDate(result.initialCycleDay)
          const endPeriod = moment(period.endDate)
          setFilters({
            ...filters,
            initialCycleDay: result.initialCycleDay,
            periodMonth: endPeriod.month()+1,
            periodYear: endPeriod.year(),
            startPeriod: moment(period.startDate).startOf('day').toDate(),
            endPeriod: endPeriod.endOf('day').toDate()
          })
          debounceOnClick()
        }
      }
    }
  }, [filters.client]);

  React.useEffect(() => {
    loadFlexiClosureDates(filters.client, filters.project, filters.periodMonth, filters.periodYear);
    debounceOnClick()
  }, [filters.project]);

  React.useEffect(() => {
    if (flexiClosureDates) {
      onSubmit(filters)
    }
  }, [flexiClosureDates]);

  const loadFlexiClosureDates = async (idClient, idProject, month, year) => {
    setLoading(true);
    if (idProject) {
      const response = await api.makeHttpRequest({
        url: `/flexiclosuredate/by-project-and-period/${idProject}?month=${month}&year=${year}`
      });
      setFlexiClosureDates(response ?? [])
    } else {
      const response = await api.makeHttpRequest({
        url: `/flexiclosuredate/by-client-and-period/${idClient}?month=${month}&year=${year}`
      });
      setFlexiClosureDates(response ?? [])
    }
    setLoading(false);
  }

  const loadCoordinationByIdClient = async (idClient = "") => {
    try {
        setLoading(true);
        const response = await api.makeHttpRequest({
            url: `/flexiclosure/projects-by-client/${idClient}`
        });
        setProjectOptions(response.projects ?? []);
        setProfessionalOptions(response.professionals ?? []);
    } catch (e) {
        setLoading(false);
        Swal.fire(
            "Erro!",
            e?.response?.data ? e.response.data : "Erro ao carregar as coordenações, tente novamente mais tarde.",
            "error"
        );
    } finally {
        setLoading(false);
    }
  };

  const handleChange = e => {
    if (e.target.name === 'client') {
      setFilters({
        ...filters,
        client: e.target.value,
        project: ''
      })
      setFlexiClosureDates([])
      
      debounceOnClick()
      return
    }

    setFilters({ ...filters, [e.target.name]: e.target.value });
    
    debounceOnClick()
  };

  const handleChangeYear = e => {
    const filterDates = getDefaultDate(e.target.value, filters.periodMonth);   
    
    const cycleDay = parseInt(filters.initialCycleDay, 10);
    var startDate = moment(filterDates.endDate).date(cycleDay).startOf('day');
    var endDate = moment(startDate).date(cycleDay).add('1', 'month').subtract('1', 'day').endOf('day');

    loadFlexiClosureDates(filters.client, filters.project, filters.periodMonth, e.target.value);

    setFilters({ 
      ...filters,
      periodYear: e.target.value
    });
    
    debounceOnClick()
  };

  const handleChangeMonth = e => {
    loadFlexiClosureDates(filters.client, filters.project, e.target.value, filters.periodYear);

    setFilters({ 
      ...filters, 
      periodMonth: e.target.value
    });
    
    debounceOnClick()
  };

  const debounceOnClick = debounce(() => onSubmit(filters), 400);

  useEffect(() => {
    if (reload) {
      onSubmit(filters);
    }
  }, [reload]);

  React.useEffect(() => {
    if (filters.coordination) {
      loadProjectByIdCoordination(filters.coordination);
    } else {
      loadProjectByIdCoordination();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.coordination]);

  const loadProjectByIdCoordination = async (idCoordination = "") => {
    if (user.role.idRole != 1) {
      try {
        setLoading(true);
        if (idCoordination !== "") {
          const projectReq = await api.makeHttpRequest({
            url: `/approvalreport/projects/coordination-by-user/${idCoordination}`
          });
          setProfessionalOptions(projectReq.professionals ?? []);
        } else {
          if (filters.client) {
            const coordination = await api.makeHttpRequest({
              url: `/approvalreport/coordinations-by-user/client/${filters.client}`
            });
            setProfessionalOptions(coordination.professionals ?? []);
          }
        }
      } catch (e) {
          setLoading(false);
          Swal.fire(
            "Erro!",
            e?.response?.data ? e.response.data : "Erro ao carregar os projetos, tente novamente mais tarde.",
            "error"
          );
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
        <div>
            <Container>
                {/* Cliente */}
                <InputField
                as="select"
                name="client"
                value={filters.client}
                onChange={handleChange}
                >
                <option value="">Cliente</option>
                {filterOptions?.clients?.length > 0 &&
                    filterOptions.clients.map(c => (
                    <option key={c.idClient} value={c.idClient}>
                        {c.name}
                    </option>
                    ))}
                </InputField>
                <div>
                    {/* Ano Período */}
                    <InputField
                        as="select"
                        name="periodYear"
                        value={filters.periodYear}
                        onChange={handleChangeYear}
                        style={{width: '90px'}}
                    >
                        {years.length > 0 &&
                            years.map(y => (
                            <option key={y} value={y}>
                                {y}
                            </option>
                        ))}
                    </InputField>
                </div>
            
                <div>
                    {/* Mês Período */}
                    <InputField
                        as="select"
                        name="periodMonth"
                        value={filters.periodMonth}
                        onChange={handleChangeMonth}
                        style={{width: '80px'}}
                    >
                        {
                            months.length > 0 &&
                            months.map(m => (
                                <option key={m.cod} value={m.cod}>
                                    {m.name}
                                </option>
                            ))
                        }
                    </InputField>
                </div>        

                {/* Coordenação */}
                <InputField
                    as="select"
                    name="project"
                    value={filters.project}
                    onChange={handleChange}
                >
                <option value="">Projeto</option>
                {        
                    projectOptions.length > 0 &&
                    projectOptions.map(p => (
                        <option key={p.idProject} value={p.idProject}>
                            {p.name}
                        </option>
                    )
                    )
                }
                </InputField>

                {/* Coordenador de projeto */}
                <InputField
                as="select"
                name="coordinator"
                value={filters.coordinator}
                onChange={handleChange}
                >
                <option value="">Coordenador de Projeto</option>
                {filterOptions?.coordinators?.length > 0 &&
                    filterOptions.coordinators.map(c => (
                    <option key={c.idUser} value={c.idUser}>
                        {c.name}
                    </option>
                    ))}
                </InputField>

                {/* Profissional */}
                <InputField
                as="select"
                name="employee"
                value={filters.employee}
                onChange={handleChange}
                >
                <option value="">Profissional</option>
                {professionalOptions.length > 0 &&
                    professionalOptions.map(c => (
                    <option key={c.idUser} value={c.idUser}>
                        {c.name}
                    </option>
                    ))}
                </InputField>

                <Button type="button" variant="filled" onClick={() => debounceOnClick()} id="filterButtonFlex">
                  <span className="icon">
                      <FiFilter size={22} />
                  </span>{" "}
                  <span>filtrar</span>
                </Button>
            </Container>
        </div>
    </>
  );
};

export default Filters;
