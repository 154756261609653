import React from "react";
import Datatable from "../../../../partials/datatable/Datatable";
import Swal from "sweetalert2";
import { InexApiService } from "../../../../services/InexApiService";
import CrudActions from "../../../../partials/datatable/CrudActions";
import AdminDropdown from "../../../../partials/content/CustomDropdowns/AdminDropdown";
import Moment from "moment";
import Loading from "../../../home/components/Loading";
import { DateTimeIcon } from "../../../../components/IconsSVG";
import {
  StyledTableCell,
  StyledTableRow
} from "../../../../partials/datatable/styles";
import store from "../../../../store/store";
import { ERoles } from "../../../../enums/Roles";
import moment from "moment";

const currentYear = new Date().getFullYear();

const years = []

for (let year = currentYear-5; year <= currentYear+5; year++) {
  years.push({ value: year.toString(), label: year.toString() });
};

const months = [
  'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 
  'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
];

const initialState = {
  flexiClosureDates: [],
  selectFilter: {value: currentYear.toString(), label: currentYear.toString()},
  reload: false,
  loading: false
};

export default class ClosingDateFlexListing extends React.Component {
  constructor(props) {
    super(props);

    const { auth } = store.getState();
    this.auth = auth;

    this.api = new InexApiService();

    this.state = {
      ...initialState
    };

    this.formatRow = this.formatRow.bind(this);
    this.setFlexiClosureDates = this.setFlexiClosureDates.bind(this);
  }

  setFlexiClosureDates = flexiClosureDates => {
    this.setState({ flexiClosureDates });
  };

  formatRow = r => {
    return (
      <StyledTableRow hover tabIndex={-1} key={`${r.project.idProject}-${r.month-1}-${r.year}`}>
        <StyledTableCell scope="row" align="center">
            <CrudActions
              actions={["edit"]}
              module="data-fechamento-flexivel"
              id={`${r.project.client.idClient}-${r.project.idProject}-${r.month-1}-${r.year}`}
              obj={r}
            />
          </StyledTableCell>
        <StyledTableCell scope="row" align="left" width="25%">
          {!!r.project && !!r.project.client && r.project.client.name}
        </StyledTableCell>
        <StyledTableCell scope="row" align="left" width="50%">
          {!!r.project && r.project.name}
        </StyledTableCell>
        <StyledTableCell scope="row" align="center">
          {months[r.month-1]}
        </StyledTableCell>
        <StyledTableCell scope="row" align="center">
          {r.year}
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  render() {
    window.setPageTitle("Listagem de Data de Fechamento Flexível - Admin");

    let headRows = [
      { label: "Ações", align: "center", width: "5%" },
      { column: "Project.Client.Name", label: "Nome do Cliente", width: "29%" },
      { column: "Project.Name", label: "Nome do Projeto", width: "29%" },
      { column: "Month", label: "Mês", align: "center" },
      { column: "Year", label: "Ano", align: "center" }
    ];

    let showAdminButtons = [
      {
        label: "Adicionar",
        onClick: null,
        icone: "add",
        path: "/admin/data-fechamento-flexivel/adicionar"
      }
    ]

    return (
      <>
        <Loading isLoading={this.state.loading} />
        <Datatable
          title="Datas de Fechamento Flexível"
          icon={DateTimeIcon}
          endpoint="/flexiclosuredate/paged"
          className="h-100"
          headRows={headRows}
          formatRow={this.formatRow}
          filterType={this.state.selectFilter?.value}
          rows={this.state.flexiClosureDates}
          setRows={this.setFlexiClosureDates}
          reload={this.state.reload}
          placeholderSearch="Busque por nome do projeto ou cliente"
          mobile={AdminDropdown}
          width={this.props.width}
          hiddenReload
          selects={[{
            placeholder: "Selecionar filtro",
            value: this.state.selectFilter,
            onChange: value => this.setState({ selectFilter: value }),
            options: [...years.sort((a,b) => Number(b.value) - Number(a.value))]
          }]}
          buttons={showAdminButtons}
        />
      </>
    );
  }
}
