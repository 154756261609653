import styled from "styled-components";

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #4672C5;
  background: ${({ type }) => (type === "button" ? "#4672C5" : "transparent")};

  font-family: "Cera Pro Regular", sans-serif;
  font-weight: 300;
  font-size: 10px;
  line-height: 150%;
  letter-spacing: 1px;
  text-transform: uppercase;
  white-space: nowrap;

  color: ${({ type }) => (type === "button" ? "#fff" : "#4672C5")};

  padding: 0 24px;

  ${({bgColor}) => bgColor && `
    background: ${bgColor};
    border-color: ${bgColor};
  `}
`;

export const ButtonFilter = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 46px;
  width: 100%;
  max-width: 140px;
  border-radius: 4px;
  border: 1px solid #4672C5;
  background: ${({ variant }) =>
    variant === "filled" ? "#4672C5" : "transparent"};

  font-family: "Cera Pro Regular", sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${({ variant }) => (variant === "filled" ? "#fff" : "#4672C5")};

  box-shadow: ${({ variant }) =>
    variant === "filled"
      ? "0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)"
      : "none"};

  span:first-child {
    margin-right: 10px;
  }

  &:disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
`;

export const CustomConfirmButton = styled.button`
  background: ${({ disabled }) => (disabled ? "gray" : "#4672C5")};
  color: ${({ disabled }) => (disabled ? "#ccc" : "#fff")};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  opacity: ${({ disabled }) => (disabled ? "0.6" : "1")};
  border: 1px solid ${({ disabled }) => (disabled ? "gray" : "#4672C5")};
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: bold;
  transition: background 0.3s ease, opacity 0.3s ease;
`;

