import React from "react";
import Datatable from "../../../../partials/datatable/Datatable";
// import Swal from "sweetalert2";
import { InexApiService } from "../../../../services/InexApiService";
import AdminDropdown from "../../../../partials/content/CustomDropdowns/AdminDropdown";
import Moment from "moment";
import Loading from "../../../home/components/Loading";
import { EndOfPeriodIcon } from "../../../../components/IconsSVG";
import {
  StyledTableCell,
  StyledTableRow
} from "../../../../partials/datatable/styles";
import { Link } from "react-router-dom";

const initialState = {
  projects: [],
  reload: false,
  loading: false
};

export default class EndOfPeriodFlexListing extends React.Component {
  constructor(props) {
    super(props);

    this.api = new InexApiService();

    this.state = {
      ...initialState
    };

    this.formatRow = this.formatRow.bind(this);
    this.setProjects = this.setProjects.bind(this);
  }

  setProjects = projects => {
    this.setState({ projects });
  };

  formatRow = r => {
    const crudActions = ["edit"];

    return (
      <StyledTableRow hover tabIndex={-1} key={r.idProject}>
        <StyledTableCell scope="row" align="left">
          <Link
            to={`/admin/fechamento-flexivel?idClient=${r.client.idClient}&idProject=${r.idProject}`}
            className="cta-link"
          >
            {r.name && r.name}
          </Link>
        </StyledTableCell>
        <StyledTableCell scope="row" al ign="left">
          {r.client && r.client?.name}
        </StyledTableCell>
        <StyledTableCell scope="row" al ign="left">
          {r.coordinator && r.coordinator?.name}
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  render() {
    window.setPageTitle("Listagem de Projetos com Fechamento Flexível - Admin");

    const headRows = [
      { column: "Name", label: "Projeto" },
      { column: "Client.Name", label: "Cliente" },
      { column: "Coordinator.Name", label: "Coordenador de Operações" },
    ];
    
    return (
      <>
        <Loading isLoading={this.state.loading} />
        <Datatable
          title="Projetos com Fechamento Flexível"
          icon={EndOfPeriodIcon}
          endpoint="/flexiclosure/paged"
          className="h-100"
          headRows={headRows}
          formatRow={this.formatRow}
          rows={this.state.projects}
          setRows={this.setProjects}
          reload={this.state.reload}
          placeholderSearch="Busque por cliente ou projeto"
          mobile={AdminDropdown}
          width={this.props.width}
          hiddenReload
        />
      </>
    );
  }
}
