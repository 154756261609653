import moment from "moment";
import React from "react";
import { Card, Col, Form, Tab, Tabs} from "react-bootstrap";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { PDFFileIcon, ReportIcon } from "../../../../components/IconsSVG";
import { InexApiService } from "../../../../services/InexApiService";
import Loading from "../../../home/components/Loading";
import { AdminTitle } from "../../components/AdminTitle";
import { StyledCardBody } from "../../components/AdminCard/style";
import { DateFilter } from "../../components/DateFilter";
import { InputField } from "../../components/InputField";
import UsersModal from "./components/UsersModal";
import ProjectTypesModal from "./components/ProjectTypesModal";
import { BiEraser } from "react-icons/bi";
import { FiFilter } from "react-icons/fi";
import { makeStyles } from "@material-ui/core/styles/";
import { ButtonContainer, StyledForm } from "./styles";
import { TabsContainer } from "./styles";
import { ReportCard } from "./components/ReportCard";
import { getPortoDefaultDate } from "../../../../utils/GetPortoDefaultDate";
import { formatSimplifiedDate } from "../../../../utils/FormatSimplifiedDate"
import { useSelector } from "react-redux";
import { ERoles } from "../../../../enums/Roles";

const useStyles = makeStyles(() => ({
  buttonToggle: {
    margin: "3px -10px",
    "& svg": {
      fill: "#807e80"
    },
    "&:hover": {
      "& svg": {
        fill: "#4672C5"
      }
    }
  },
  div: {
    display: "flex",
    flexDirection: "row"
  }
}));

const initialFilterDates = getPortoDefaultDate(22);

const Reports = props => {
  const classes = useStyles();
  const api = new InexApiService();

  const [loading, setLoading] = React.useState(false);
  const [reload, setReload] = React.useState(false);

  const [usersSelected, setUsersSelected] = React.useState([]);
  const [projectTypesSelected, setProjectTypesSelected] = React.useState([]);

  const [key, setKey] = React.useState("AlocatorWithoutRequest");
  const [listOfTimersAgroupedByUserAndProject, setListOfTimersAgroupedByUserAndProject] = React.useState([]);
  const [listOfTimersAgroupedByUserAndProjectNotAllocated, setListOfTimersAgroupedByUserAndProjectNotAllocated] = React.useState([]);
  const [listOfTimersAgroupedByUserAndProjectWithoutCoordinatorApproval, setListOfTimersAgroupedByUserAndProjectWithoutCoordinatorApproval] = React.useState([]);
  const [period, setPeriod] = React.useState({});

  const user = useSelector(state => state.auth.user);
  const isCoordinator = user?.role?.type === ERoles.OPERATION;

  const [filters, setFilters] = React.useState({
    clientCoordinator: "",
    clientManager: "",
    client: "",
    project: "",
    employee: "",
    inexCoordinator: isCoordinator ? `${user.idUser}` : "",
    coordinations: "",
    startPeriod: initialFilterDates.startDate,
    endPeriod: initialFilterDates.endDate,
  });

  const [periodOpt, setPeriodOpt] = React.useState({
    label: `${moment(initialFilterDates.startDate)
      .format("DD/MM/YYYY")} - ${moment(initialFilterDates.endDate).format(
        "DD/MM/YYYY"
      )}`,
    value: 1
  });
  const [clientProps, setClientProps] = React.useState({
    initialCycleDay: 22
  });
  const [clientOptions, setClientOptions] = React.useState([]);
  const [projectOptions, setProjectOptions] = React.useState([]);
  const [coordinatorOptions, setCoordinatorOptions] = React.useState([]);
  const [coordinationOptions, setCoordinationOptions] = React.useState([]);
  const [professionalsOptions, setProfessionalOptions] = React.useState([]);
  const [projectTypesOptions, setProjectTypesOptions] = React.useState([]);
  const [allProfessionalsOptions, setAllProfessionalOptions] = React.useState([]);
  const [loadingSelects, setLoadingSelects] = React.useState(false);

  React.useEffect(() => {
    window.setPageTitle("Relatórios - Admin");
    loadAllSelects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (loadingSelects) {
      const periodCycle = getPortoDefaultDate(user.clientDefault ? user.clientDefault.initialCycleDay : 22);
      const period = {
        startAt: moment(periodCycle.startDate).subtract(3, 'hour').toDate(),
        endAt: moment(periodCycle.endDate).subtract(3, 'hour').toDate()
      }
      getListOfTimersAgroupedByUserAndProjectToAllocatorInitial(period);
      getListOfTimersAgroupedByUserAndProjectNotAllocatedToAllocatorInitial(period);
      getListOfTimersAgroupedByUserAndProjectNotLoadedToCoordinatorInitial(period);
      setProjectTypesSelected(projectTypesOptions.map((projectType) => projectType.idProjectType))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingSelects])

  React.useEffect(() => {
    if (filters.client) {
      loadCoordinationByIdClient(filters.client);
      loadClientProps(filters.client)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.client]);

  React.useEffect(() => {
    if (filters.coordination) {
      loadProjectByIdCoordination(filters.coordination);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.coordination]);

  const loadClientProps = async (idClient) => {
    const client = await api.makeHttpRequest({
      url: `/client/${idClient}`
    });
    if (client) {
      setClientProps(client);
      const periodCycle = getPortoDefaultDate(client.initialCycleDay);
      handlePeriodFilter({
        startPeriod: periodCycle.startDate,
        endPeriod: periodCycle.endDate
      });
    }
  }

  const loadAllSelects = React.useCallback(async () => {
    try {
      setLoading(true);
      const selects = await api.makeHttpRequest({
        url: "/approvalreport/selects"
      });
      if (selects) {
        setUsersSelected([]);
        setClientOptions(selects.clients ?? []);
        setCoordinatorOptions(selects.coordinators ?? []);
        setProfessionalOptions(selects.professionals ?? []);
        //Aqui vamos guardar a lista original com todos os Profissionais. Isso é importante pois teremos casos que esses profissionais são filtrados,
        // e caso venhamos zerar os filtros, temos a lista orgiginal para usar.
        setAllProfessionalOptions(selects.professionals ?? []);
        setProjectTypesOptions(selects.projectTypes ?? []);
        //aqui temos que aplicar o filtro nos profissionais caso quem esteja acessando seja um coordenador de Operaçoes Inex.
        if(isCoordinator){
          loadProfessionalsByIdCoordinator(filters.inexCoordinator);
        }

        if (selects.clients && selects.clients.length > 0) {
          if (user.clientDefault) {
            const periodCycle = getPortoDefaultDate(user.clientDefault.initialCycleDay);
            handlePeriodFilter({
              startPeriod: periodCycle.startDate,
              endPeriod: periodCycle.endDate
            });
            setFilters({
              ...filters,
              client: user.clientDefault.idClient
            })
          } else {
            const firstClient = selects.clients[0]
            const periodCycle = getPortoDefaultDate(firstClient.initialCycleDay);
            handlePeriodFilter({
              startPeriod: periodCycle.startDate,
              endPeriod: periodCycle.endDate
            });
            setFilters({
              ...filters,
              client: firstClient.idClient
            })
          }
        }

        setLoadingSelects(true);
        setLoading(false);
      }
    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data ? e.response.data : "Erro ao carregar dados, tente novamente mais tarde.",
        "error"
      );
      setLoading(false);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadCoordinationByIdClient = React.useCallback(async (idClient = "") => {
    if (user.role.idRole != 1) {
      try {
        setLoading(true);
        const coordination = await api.makeHttpRequest({
          url: `/approvalreport/coordinations-by-user/client/${idClient}`
        });

        setUsersSelected([]);
        setProjectOptions("");
        setCoordinationOptions(coordination.coordinations ?? []);
        setProfessionalOptions(coordination.professionals ?? []);
      } catch (e) {
          setLoading(false);
          Swal.fire(
            "Erro!",
            e?.response?.data ? e.response.data : "Erro ao carregar as coordenações, tente novamente mais tarde.",
            "error"
          );
      } finally {
        setLoading(false);
      }
    } else {
      try {
        setLoading(true);
        const coordination = await api.makeHttpRequest({
          url: `/approvalreport/coordinations/client/${idClient}`
        });

        setUsersSelected([]);
        setProjectOptions("");
        setCoordinationOptions(coordination.coordinations ?? []);
        setProfessionalOptions(coordination.professionals ?? []);
      } catch (e) {
          setLoading(false);
          Swal.fire(
            "Erro!",
            e?.response?.data ? e.response.data : "Erro ao carregar as coordenações, tente novamente mais tarde.",
            "error"
          );
      } finally {
        setLoading(false);
      }
    }
  }, []);

  const loadProjectByIdCoordination = React.useCallback(async (idCoordination = "") => {
    if (user.role.idRole != 1) {
      try {
        setLoading(true);
        const projectReq = await api.makeHttpRequest({
          url: `/approvalreport/projects/coordination-by-user/${idCoordination}`
        });

        setUsersSelected([]);
        setProjectOptions(projectReq.projects ?? []);
      } catch (e) {
          setLoading(false);
          Swal.fire(
            "Erro!",
            e?.response?.data ? e.response.data : "Erro ao carregar os projetos, tente novamente mais tarde.",
            "error"
          );
      } finally {
        setLoading(false);
      }
    } else {
      try {
        setLoading(true);
        const projectReq = await api.makeHttpRequest({
          url: `/approvalreport/projects/coordination/${idCoordination}`
        });

        setUsersSelected([]);
        setProjectOptions(projectReq.projects ?? []);
        setProfessionalOptions(projectReq.professionals ?? []);
      } catch (e) {
          setLoading(false);
          Swal.fire(
            "Erro!",
            e?.response?.data ? e.response.data : "Erro ao carregar os projetos, tente novamente mais tarde.",
            "error"
          );
      } finally {
        setLoading(false);
      }
    }
  }, []);

  const loadProfessionalsByIdProject = React.useCallback(async (idProject = "") => {
    try {
      setLoading(true);
      const professionalReq = await api.makeHttpRequest({
        url: `/approvalreport/professionals/project/${idProject}`
      });

      setUsersSelected([]);
      setProfessionalOptions(professionalReq.professionals ?? []);
    } catch (e) {
        setLoading(false);
        Swal.fire(
          "Erro!",
          e?.response?.data ? e.response.data : "Erro ao carregar os profissionais, tente novamente mais tarde.",
          "error"
        );
    } finally {
      setLoading(false);
    }
  }, []);

  const loadProfessionalsByIdCoordinator = React.useCallback(async (idCoordinator = "") => {
    try {
      setLoading(true);
      const professionalReq = await api.makeHttpRequest({
        url: `/approvalreport/professionals/coordinator/${idCoordinator}`
      });

      setUsersSelected([]);
      setProfessionalOptions(professionalReq.professionals ?? []);
    } catch (e) {
        setLoading(false);
        Swal.fire(
          "Erro!",
          e?.response?.data ? e.response.data : "Erro ao carregar os profissionais, tente novamente mais tarde.",
          "error"
        );
    } finally {
      setLoading(false);
    }
  }, []);

  const handlePeriodFilter = filter => {
    const periodString = {
      label: `${moment(filter.startPeriod)
        .format("DD/MM/YYYY")} - ${moment(filter.endPeriod).format(
          "DD/MM/YYYY"
        )}`,
      value: 1
    };

    setPeriodOpt(periodString);

    setFilters({
      ...filters,
      startPeriod: filter.startPeriod,
      endPeriod: filter.endPeriod
    });
  };

  const handleProjectListUpdate = (idx, tab) => {
    // clona o objeto listOfTimersAgroupedByUserAndProject
    let newList = {...listOfTimersAgroupedByUserAndProject};


    if(tab === "AlocatorWithoutRequest"){
      // remove o item da lista withoutRequest e armazena em uma variável temporária
      const itemToMove = newList.withoutRequest.splice(idx, 1)[0];
      // adiciona o item na lista withRequest
      newList.withRequest.push(itemToMove);
    }else if (tab === "AlocatorWithRequest") {

      // remove o item da lista withRequest e armazena em uma variável temporária
      const itemToMove = newList.withRequest.splice(idx, 1)[0];
      // adiciona o item na lista withoutRequest
      newList.withoutRequest.push(itemToMove);
    }

    newList.withRequest.sort((a, b) => a.alocatorName.localeCompare(b.alocatorName));
    newList.withoutRequest.sort((a, b) => a.alocatorName.localeCompare(b.alocatorName));
    // atualiza a variável de estado com o novo objeto
    setListOfTimersAgroupedByUserAndProject(newList);
  };

  const handleChange = e => {
    if(e.target.name === "coordination") {
      e.target.value === "" && loadAllSelects();
    }

    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const handlefilter = () => {
    const period = {
      startAt: moment(filters.startPeriod).startOf('day').subtract(3, 'hour').toDate(),
      endAt: moment(filters.endPeriod).endOf('day').subtract(3, 'hour').toDate()
    }
    getListOfTimersAgroupedByUserAndProjectToAllocator(period);
    getListOfTimersAgroupedByUserAndProjectNotAllocatedToAllocator(period);
    getListOfTimersAgroupedByUserAndProjectNotLoadedToCoordinator(period);
  };

  const handleExport = React.useCallback(async () => {
    setLoading(true);

    try {
      const startPeriod = moment(filters.startPeriod).hour(0).minute(0).seconds(0)
      const endPeriod = moment(filters.endPeriod).hour(23).minute(59).seconds(59)

      const params = {
        startPeriod: startPeriod.subtract(3, 'hour').toDate(),
        endPeriod: endPeriod.subtract(3, 'hour').toDate(),
        hasPeriod: true,
        idClient: filters.client,
        idProject: filters.project,
        idCoordinator: filters.inexCoordinator,
        idCoordination: filters.coordination,
        professionalListIds: usersSelected.toString(),
        projectTypeListIds: projectTypesSelected.toString()
      };
      const response = await api.makeHttpRequest({
        url: "/approvalreport/export",
        method: "get",
        params,
        responseType: "blob"
      });

      //Bom, o nome do arquivo vem dentro do response.headers, mas ele vem diferente de um objeto, então
      //para conseguir recuperar o nome do arquivo, temos que usar essa expressão regular abaixo.
      const contentDispositionHeader = response.headers['content-disposition'];
      const fileNameMatch = contentDispositionHeader.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
      const fileName = fileNameMatch[1].replace(/['"]/g, '');

      const blob = new Blob([response.data]);
      const url = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.download = fileName;
      link.click();

      Swal.fire(
        "Sucesso!",
        `Relatório(s) gerado(s) com sucesso.`,
        "success"
      );
    } catch (e) {
      const reader = new FileReader();

      reader.addEventListener('loadend', (e) => {
        const text = e.srcElement.result;
        Swal.fire(
          "Erro!",
          text ? text : "Erro ao gerar relatório.",
          "error"
        );
      });

      reader.readAsText(e.response.data);
    }

    setLoading(false);
  }, [
    api,
    filters.client,
    filters.clientCoordinator,
    filters.clientManager,
    filters.employee,
    filters.endPeriod,
    filters.inexCoordinator,
    filters.project,
    filters.coordination,
    filters.startPeriod,
    props.email,
    usersSelected
  ]);

  const getListOfTimersAgroupedByUserAndProjectToAllocatorInitial = async (period) => {
    setLoading(true)
    try {
      const params = {
        startPeriod: period.startAt,
        endPeriod: period.endAt,
        hasPeriod: true,
        idClient: filters.client,
        idProject: filters.project,
        idCoordinator: filters.inexCoordinator,
        idCoordination: filters.coordination,
        professionalListIds: usersSelected.toString(),
        projectTypeListIds: projectTypesOptions.map((projectType) => projectType.idProjectType).join(',')
      };

      const resListOfTimersAgroupedByUserAndProject = await api.makeHttpRequest({
        url: "/approvalreport/listoftimers-grouped-byuser-andproject-toallocator",
        params
      });
      setListOfTimersAgroupedByUserAndProject(resListOfTimersAgroupedByUserAndProject);

    } catch (e) {
      console.log(e)
      Swal.fire(
        "Erro!",
        e?.response?.data ? e.response.data : "Erro ao buscar os dados.",
        "error"
      );
    }
    setLoading(false)
  };

  const getListOfTimersAgroupedByUserAndProjectToAllocator = React.useCallback(async (period) => {
    setLoading(true)

    try {
      const params = {
        startPeriod: period.startAt,
        endPeriod: period.endAt,
        hasPeriod: true,
        idClient: filters.client,
        idProject: filters.project,
        idCoordinator: filters.inexCoordinator,
        idCoordination: filters.coordination,
        professionalListIds: usersSelected.toString(),
        projectTypeListIds: projectTypesSelected.toString()
      };

      const resListOfTimersAgroupedByUserAndProject = await api.makeHttpRequest({
        url: "/approvalreport/listoftimers-grouped-byuser-andproject-toallocator",
        params
      });
      setListOfTimersAgroupedByUserAndProject(resListOfTimersAgroupedByUserAndProject);

    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data ? e.response.data : "Erro ao buscar os dados.",
        "error"
      );
    }
    setLoading(false)
  }, [
    api,
    filters.client,
    filters.clientCoordinator,
    filters.clientManager,
    filters.employee,
    filters.endPeriod,
    filters.inexCoordinator,
    filters.project,
    filters.coordination,
    filters.startPeriod,
    props.email,
    usersSelected
  ]);

  const getListOfTimersAgroupedByUserAndProjectNotAllocatedToAllocator = React.useCallback(async (period) => {
    setLoading(true)
    try {
      const params = {
        startPeriod: period.startAt,
        endPeriod: period.endAt,
        hasPeriod: true,
        idClient: filters.client,
        idProject: filters.project,
        idCoordinator: filters.inexCoordinator,
        idCoordination: filters.coordination,
        professionalListIds: usersSelected.toString(),
        projectTypeListIds: projectTypesSelected.toString()
      };

      const resListOfTimersAgroupedByUserAndProjectNotAllocated = await api.makeHttpRequest({
        url: "/approvalreport/listoftimers-grouped-byuser-andproject-notallocated-toallocator",
        params
      });

      setListOfTimersAgroupedByUserAndProjectNotAllocated(resListOfTimersAgroupedByUserAndProjectNotAllocated);

    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data ? e.response.data : "Erro ao buscar os dados.",
        "error"
      );
    }
    setLoading(false)
  }, [
    api,
    filters.client,
    filters.clientCoordinator,
    filters.clientManager,
    filters.employee,
    filters.endPeriod,
    filters.inexCoordinator,
    filters.project,
    filters.coordination,
    filters.startPeriod,
    props.email,
    usersSelected
  ]);

  const getListOfTimersAgroupedByUserAndProjectNotAllocatedToAllocatorInitial = async (period) => {
    setLoading(true)
    try {
      const params = {
        startPeriod: period.startAt,
        endPeriod: period.endAt,
        hasPeriod: true,
        idClient: filters.client,
        idProject: filters.project,
        idCoordinator: filters.inexCoordinator,
        idCoordination: filters.coordination,
        professionalListIds: usersSelected.toString(),
        projectTypeListIds: projectTypesSelected.toString()
      };

      const resListOfTimersAgroupedByUserAndProjectNotAllocated = await api.makeHttpRequest({
        url: "/approvalreport/listoftimers-grouped-byuser-andproject-notallocated-toallocator",
        params
      });

      setListOfTimersAgroupedByUserAndProjectNotAllocated(resListOfTimersAgroupedByUserAndProjectNotAllocated);

    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data ? e.response.data : "Erro ao buscar os dados.",
        "error"
      );
    }
    setLoading(false)
  };

  const getListOfTimersAgroupedByUserAndProjectNotLoadedToCoordinator = React.useCallback(async (period) => {
    setLoading(true)
    try {

      const params = {
        startPeriod: period.startAt,
        endPeriod: period.endAt,
        hasPeriod: true,
        idClient: filters.client,
        idProject: filters.project,
        idCoordinator: filters.inexCoordinator,
        idCoordination: filters.coordination,
        professionalListIds: usersSelected.toString(),
        projectTypeListIds: projectTypesSelected.toString()
      };

      const resListOfTimersAgroupedByUserAndProjectWithoutCoordinatorApproval = await api.makeHttpRequest({
        url: "/approvalreport/listoftimers-grouped-byuser-andproject-notloaded-tocoordinator",
        params
      });

      setListOfTimersAgroupedByUserAndProjectWithoutCoordinatorApproval(resListOfTimersAgroupedByUserAndProjectWithoutCoordinatorApproval);

    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data ? e.response.data : "Erro ao buscar os dados.",
        "error"
      );
    }
    setLoading(false)
  }, [
    api,
    filters.client,
    filters.clientCoordinator,
    filters.clientManager,
    filters.employee,
    filters.endPeriod,
    filters.inexCoordinator,
    filters.project,
    filters.coordination,
    filters.startPeriod,
    props.email,
    usersSelected
  ]);

  const getListOfTimersAgroupedByUserAndProjectNotLoadedToCoordinatorInitial = async (period) => {
    setLoading(true)
    try {
      const params = {
        startPeriod: period.startAt,
        endPeriod: period.endAt,
        hasPeriod: true,
        idClient: filters.client,
        idProject: filters.project,
        idCoordinator: filters.inexCoordinator,
        idCoordination: filters.coordination,
        professionalListIds: usersSelected.toString(),
        projectTypeListIds: projectTypesSelected.toString()
      };

      const resListOfTimersAgroupedByUserAndProjectWithoutCoordinatorApproval = await api.makeHttpRequest({
        url: "/approvalreport/listoftimers-grouped-byuser-andproject-notloaded-tocoordinator",
        params
      });

      setListOfTimersAgroupedByUserAndProjectWithoutCoordinatorApproval(resListOfTimersAgroupedByUserAndProjectWithoutCoordinatorApproval);

    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data ? e.response.data : "Erro ao buscar os dados.",
        "error"
      );
    }
    setLoading(false)
  };

  const PeriodFilter = () => {
    return (
      <InputField
        select2ClassName="disabled-normal"
        as="select2"
        name="periodOpt"
        value={periodOpt}
        placeholder="Período"
        disabled
      />
    );
  };

  const handleClearFields = () => {
    try {
      setFilters({
        clientCoordinator: "",
        clientManager: "",
        client: "",
        project: "",
        employee: "",
        inexCoordinator: "",
        coordinations: "",
        coordination: ""
      });

      // Verificar se projectTypesOptions está vazio
      if (projectTypesOptions.length === 0) {
        // Se estiver vazio, selecionar o primeiro item da lista
        setProjectTypesSelected([]);
      } else {
        setProjectTypesSelected(projectTypesOptions.map((projectType) => projectType));
      }
      setCoordinatorOptions([]);
      setCoordinationOptions([]);
      setProfessionalOptions([]);
      setUsersSelected([]);

      loadAllSelects();
    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data ?? "Não foi possível limpar os campos, tente novamente mais tarde.",
        "error"
      );
    } finally {
    }
  }

  const handleProjectTypeChange = (selectedTypes) => {
    // Se não houver nenhum tipo de projeto selecionado, marque o primeiro da lista
    if (selectedTypes.length === 0 && projectTypesOptions.length > 0) {
      selectedTypes.push(projectTypesOptions[0].value);
    }
    setProjectTypesSelected(selectedTypes);
  };


  return (
    <>
      <Loading isLoading={loading} />
      <AdminTitle title="Relatórios" icon={ReportIcon} disableHeader />
      <Card className="mt-4">
        <StyledCardBody>
          <StyledForm className="form-admin">
            {/* Row 1 */}
            <Form.Row>
              {/* Cliente */}
              <Form.Group as={Col} lg="3" xs="12">
                <InputField
                  as="select"
                  name="client"
                  value={filters.client}
                  onChange={handleChange}
                  // className={filters.inexCoordinator ? "fade" : null}
                  // disabled={filters.inexCoordinator}
                >

                  {clientOptions?.length > 0 &&
                    clientOptions.map(c => (
                      <option key={c.idClient} value={c.idClient}>
                        {c.name}
                      </option>
                    ))}
                </InputField>
              </Form.Group>

              {/* Coordenação */}
              <Form.Group as={Col} lg="3" xs="12">
                <InputField
                  as="select"
                  name="coordination"
                  value={filters.coordination}
                  onChange={handleChange}
                  className={!filters.client //|| filters.inexCoordinator
                    ? "fade" : null}
                  disabled={!filters.client//|| filters.inexCoordinator

                  }
                >
                  <option value="">Selecione uma Coordenação</option>
                  {coordinationOptions?.length > 0 &&
                    coordinationOptions.map(c => (
                      <option key={c.idProjectArea} value={c.idProjectArea}>
                        {c.name}
                      </option>
                    ))}
                </InputField>
              </Form.Group>

              {/* Projeto */}
              <Form.Group as={Col} lg="3" xs="12">
                <InputField
                  as="select"
                  name="project"
                  value={filters.project}
                  onChange={handleChange}
                  className={!filters.coordination// || filters.inexCoordinator
                    ? "fade" : null}
                  disabled={!filters.coordination// || filters.inexCoordinator
                  }
                >
                  <option value="">Selecione um Projeto</option>
                  {projectOptions?.length > 0 &&
                    projectOptions.map(p => (
                      <option key={p.idProject} value={p.idProject}>
                        {p.name}
                      </option>
                    ))}
                </InputField>
              </Form.Group>

              {/* Tipos de Projeto */}
              <Form.Group as={Col} lg="3" xs="12">
                <ProjectTypesModal
                  setProjectTypesSelected={handleProjectTypeChange}
                  projectTypes={projectTypesOptions}
                  projectSelected={filters.project}
                />
              </Form.Group>
            </Form.Row>

            {/* Row 2 */}
            <Form.Row>
              {/* Coordenador do projeto (INEX) */}
              <Form.Group as={Col} lg="3" xs="12">
                <InputField
                  as="select"
                  name="inexCoordinator"
                  value={filters.inexCoordinator}
                  onChange={handleChange}
                  //className={filters.client ? "fade" : null}
                  //disabled={filters.client}
                >
                  <option value="">Selecione um Coordenador (INEX)</option>
                  {coordinatorOptions?.length > 0 &&
                    coordinatorOptions.map(c => (
                      <option key={c.idUser} value={c.idUser}>
                        {c.name}
                      </option>
                    ))}
                </InputField>
              </Form.Group>

              {/* Profissional */}
              <Form.Group as={Col} lg="3" xs="12">
                <UsersModal
                  setUsersSelected={setUsersSelected}
                  coordinatorId={filters.inexCoordinator ?? ''}
                  professionals={professionalsOptions}
                />
              </Form.Group>

               {/* Período */}
               <Form.Group as={Col} lg="3" xs="12"></Form.Group>

               {/* Período */}
               <Form.Group as={Col} lg="3" xs="12">
                <DateFilter
                  component={PeriodFilter}
                  reload={reload}
                  setFilter={handlePeriodFilter}
                  initialValues={initialFilterDates}
                  initialCycle={clientProps.initialCycleDay}
                  newValues={{
                    startDate: filters.startPeriod,
                    endDate: filters.endPeriod
                  }}
                />
              </Form.Group>
            </Form.Row>
          </StyledForm>
        </StyledCardBody>
      </Card>
      <ButtonContainer>
        <button type="button" variant="filled" style={{ marginRight: 16 }} onClick={() => handlefilter()}>
          <span className="icon">
            <FiFilter size={22} />
          </span>{" "}
          <span>filtrar</span>
        </button>

        <button
          type="button"
          style={{ marginRight: 16 }}
          onClick={() => handleClearFields()}
        >
          <BiEraser size={24} /> LIMPAR
        </button>

        <button
          type="button"
          onClick={() => handleExport()}
        >
          <PDFFileIcon /> GERAR RELATÓRIO
        </button>
      </ButtonContainer>

      <TabsContainer>
          <Tabs activeKey={key} transition={false} onSelect={k => setKey(k)}>
            <Tab eventKey="AlocatorWithoutRequest" title="E-Mail Alocador Pend.">
              <Card>
                <Card.Body>
                  <div className="user-list">
                    {listOfTimersAgroupedByUserAndProject.withoutRequest?.length > 0 &&
                      listOfTimersAgroupedByUserAndProject.withoutRequest.map((item, index) => {
                        const qtdHoursNotAllocated = item.listOfProjects[0]?.qtdHoursNotAllocated || 0;
                        return (
                          <ReportCard
                            key={index}
                            tab={"AlocatorWithoutRequest"}
                            cardKey={index}
                            title={`${item.alocatorName} - ${moment(filters.startPeriod).format('DD/MMM')} à ${moment(filters.endPeriod).format('DD/MMM')}`}
                            totalHours={item.totalHours}
                            qtdHoursNotAllocated={qtdHoursNotAllocated}
                            projectList={item.listOfProjects}
                            setLoading={setLoading}
                            setReload={setReload}
                            handleProjectListUpdate={handleProjectListUpdate}
                          />
                        );
                      })}
                  </div>
                </Card.Body>
              </Card>
            </Tab>

            <Tab eventKey="AlocatorWitRequest" title="E-Mail Alocador Env.">
              <Card>
                <Card.Body>
                  <div className="user-list">
                    {listOfTimersAgroupedByUserAndProject.withRequest?.length > 0 &&
                      listOfTimersAgroupedByUserAndProject.withRequest.map((item, index) => {
                        console.log("Item no map:", item); // Inspecionar o conteúdo de item
                        const qtdHoursNotAllocated = item.listOfProjects[0]?.qtdHoursNotAllocated || 0;
                        return (
                          <ReportCard
                            key={index}
                            tab={"AlocatorWithRequest"}
                            cardKey={index}
                            title={`${item.alocatorName} - ${moment(filters.startPeriod).format('DD/MMM')} à ${moment(filters.endPeriod).format('DD/MMM')}`}
                            totalHours={item.totalHours}
                            qtdHoursNotAllocated={qtdHoursNotAllocated}
                            projectList={item.listOfProjects}
                            setLoading={setLoading}
                            setReload={setReload}
                            handleProjectListUpdate={handleProjectListUpdate}
                          />
                        );
                      })}
                  </div>
                </Card.Body>
              </Card>
            </Tab>


            <Tab eventKey="notAllocatedByAllocator" title="Não Alocados em todo Período(SMs)">
              <Card>
                <Card.Body>
                  <div className="user-list">
                    {listOfTimersAgroupedByUserAndProjectNotAllocated?.length > 0 &&
                      listOfTimersAgroupedByUserAndProjectNotAllocated.map((item, index) => {
                        const qtdHoursNotAllocated = item.listOfProjects[0]?.qtdHoursNotAllocated || 0;
                        return (
                          <ReportCard
                            key={index}
                            tab={"notAllocatedByAllocator"}
                            cardKey={index}
                            title={item.alocatorName}
                            totalHours={item.totalHours}
                            qtdHoursNotAllocated={qtdHoursNotAllocated}
                            projectList={item.listOfProjects}
                            setLoading={setLoading}
                            setReload={setReload}
                            handleProjectListUpdate={handleProjectListUpdate}
                          />
                        );
                      })}
                  </div>
                </Card.Body>
              </Card>
            </Tab>


            <Tab eventKey="notLoadedByCoordinator" title="Não Carregados em todo Período (Coordenador)">
              <Card>
                <Card.Body>
                  <div className="user-list">
                    {listOfTimersAgroupedByUserAndProjectWithoutCoordinatorApproval?.length > 0 &&
                      listOfTimersAgroupedByUserAndProjectWithoutCoordinatorApproval.map((item, index) => {
                        console.log("Item no map: 4", item);
                        return (
                          <ReportCard
                            key={index}
                            tab={"notLoadedByCoordinator"}
                            cardKey={index}
                            title={item.clientCoordinator}
                            totalAllocatedDecimalHours={item.totalAllocatedDecimalHours}
                            projectList={item.listLoadMinutesProjectListWithoutCoordinatorApproval}
                          />
                        );
                      })}
                  </div>
                </Card.Body>
              </Card>
            </Tab>
          </Tabs>

          {/* {projectListCurrentCycle?.notLoadedPastCycle?.length > 0 ? (
            <div className="timer-alert">
              <RiAlertLine />
            </div>
          ) : (
            null
          )}    */}

        </TabsContainer>
    </>
  );
};

const mapStoreToProps = store => ({
  email: store?.auth?.user?.inexEmail,
  idUser: store?.auth?.user?.idUser
});

export default connect(mapStoreToProps)(Reports);
