export const EMenus = Object.freeze({
  TIMER: Symbol(1),
  OPERACOES_APROVADOS: Symbol(2.1),
  OPERACOES_RELATORIO: Symbol(2.2),
  OPERACOES_FECHAMENTO: Symbol(2.3),
  OPERACOES_CONCILIACOES: Symbol(2.4),
  OPERACOES_FECHAMENTO_FLEXIVEL: Symbol(2.5),
  PROFISSIONAIS: Symbol(3),
  CLIENTE: Symbol(4),
  PROJETOS: Symbol(5),
  FABRICA: Symbol(6),
  RITUAIS: Symbol(7),
  FATURAMENTO_PROPOSTA: Symbol(8.1),
  FATURAMENTO_CHECK: Symbol(8.2),
  ADMINISTRATIVO_DATA_FECHAMENTO: Symbol(9.1),
  ADMINISTRATIVO_MENSAGEM_WHATSAPP: Symbol(9.2),
  ADMINISTRATIVO_FERIADOS: Symbol(9.3),
  ADMINISTRATIVO_NOTIFICACOES: Symbol(9.4),
  ADMINISTRATIVO_DATA_FECHAMENTO_FLEXIVEL: Symbol(9.5),
  TREINAMENTO: Symbol(10.1),
  TREINAMENTO_SUBGRUPO: Symbol(10.2),
  TREINAMENTO_GRUPO: Symbol(10.3)
});
