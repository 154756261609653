import React, { useState, useEffect, useCallback } from "react";
import { RiSearchLine, RiAlertLine } from "react-icons/ri";
import { Card, Tab, Tabs } from "react-bootstrap";
import { InexApiService } from "../../../../services/InexApiService";
import { ApprovalIcon } from "../../../../components/IconsSVG";
import Loading from "../../../home/components/Loading";
import { AdminTitle } from "../../components/AdminTitle";
import { ApprovalCard } from "./components/ApprovalCard";
import { ApprovalCardFactory } from "./components/ApprovalCardFactory";
import Filters from "./components/Filters";
import { SearchBar, TabsContainer } from "./styles";
import Swal from "sweetalert2";
import { ERoles } from "../../../../enums/Roles";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { MdVisibility } from "react-icons/md";
import { calculateDurationDiff } from "../../../../utils/CalculateDurationDiff";
import { calculateSumTimes , transformMinutesToHours, transformHoursToMinutes} from "../../../../utils/CalculateSumTime";
import moment from "moment";
import { debounce } from 'lodash';

const Approvals = () => {
  const api = new InexApiService();
  const user = useSelector(state => state.auth.user);

  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [proposalOptions, setProposalOptions] = useState([]);
  const [filterOptions, setFilterOptions] = useState({});

  // Tabs state
  const [key, setKey] = useState("pendentes");

  const [approvalList, setApprovalList] = useState([]);
  const [filteredApprovalList, setFilteredApprovalList] = useState([]);
  const [filtersSelect, setFiltersSelect] = useState();
  const [professionalsNotReload, setProfessionalsNotReload] = useState([]);

  useEffect(() => {
    window.setPageTitle("Aprovações - Admin");

    const loadProposals = async () => {
      const proposals = await api.makeHttpRequest({
        url: "/approval/rfq"
      });

      const formatedProposal =
        proposals.length > 0
          ? proposals.map(prop => ({
              id: prop.idBusinessProposalValue,
              name: prop.profileDescription
            }))
          : [];

      setProposalOptions(formatedProposal);
    };

    const loadSelects = async () => {
      const selects = await api.makeHttpRequest({
        url: "/approval/selects"
      });

      setFilterOptions(selects ?? {});
    };

    loadSelects();
    loadProposals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setReload(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key]);

  const onSubmit = async (filters) => {
    setLoading(true);
    setFiltersSelect(filters);
    try {
      const startPeriod = moment(filters.startPeriod).hour(0).minute(0).seconds(0)
      const endPeriod = moment(filters.endPeriod).hour(23).minute(59).seconds(59)

      const params = {
        startPeriod: startPeriod.subtract(3, 'hour').toDate(),
        endPeriod: endPeriod.subtract(3, 'hour').toDate(),
        hasPeriod: true,
        idClient: filters.client,
        idCoordination: filters.coordination,
        idCoordinator: filters.coordinator,
        idProfessional: filters.employee,
        searchText: filters.searchText // Incluindo searchText nos parâmetros
      };

      const approvals = await api.makeHttpRequest({
        url: "/approval/timers",
        params
      });
      setApprovalList(approvals);
      setFilteredApprovalList(approvals);
    } catch { }

    //setSearch('');
    setLoading(false);
  }
// Função handleSearchFilter otimizada com debounce e useCallback
const handleSearchFilter = useCallback(
  debounce(value => {
    // Lógica do filtro
    let arr = [];
    let arrApproved = Array.isArray(approvalList.approved) ? [...approvalList.approved] : [];
    let arrCreated = Array.isArray(approvalList.created) ? [...approvalList.created] : [];
    let arrFactory = Array.isArray(approvalList.factory) ? [...approvalList.factory] : [];


    if (value?.length >= 3) {
      arrApproved = arrApproved.map(x => ({
        user: x.user,
        hours: x.hours,
        minutes: x.minutes,
        timers: x.timers?.filter(t =>
          [t.activity, t.demand, t.client?.name, t.project?.name]
            .filter(Boolean)
            .some(field => field.toUpperCase().includes(value.toUpperCase()))
        ),
      }));
      arrCreated = arrCreated.map(x => ({
        user: x.user,
        hours: x.hours,
        minutes: x.minutes,
        timers: x.timers?.filter(t =>
          [t.activity, t.demand, t.client?.name, t.project?.name]
            .filter(Boolean)
            .some(field => field.toUpperCase().includes(value.toUpperCase()))
        ),
      }));
      arrFactory = arrFactory.map(x => ({
        client: x.client,
        hours: x.hours,
        minutes: x.minutes,
        timers: x.timers?.filter(t =>
          [t.activity, t.demand, t.client?.name, t.project?.name]
            .filter(Boolean)
            .some(field => field.toUpperCase().includes(value.toUpperCase()))
        ),
      }));

      arr = { created: arrCreated, approved: arrApproved, factory: arrFactory };
      setFilteredApprovalList(arr);
    } else if (value?.length === 0) {
      setFilteredApprovalList(approvalList);
    }

    setSearch(value);
  }, 300), [approvalList]); // Adiciona 300ms de debounce

  const handleAccordionExpand = async (idProfessional) => {
    //if (!professionalsNotReload.find((p) => p === idProfessional)) {
      const startPeriod = moment(filtersSelect.startPeriod).hour(0).minute(0).seconds(0)
      const endPeriod = moment(filtersSelect.endPeriod).hour(23).minute(59).seconds(59)

      const params = {
        startPeriod: startPeriod.subtract(3, 'hour').toDate(),
        endPeriod: endPeriod.subtract(3, 'hour').toDate(),
        hasPeriod: true,
        idClient: filtersSelect.client,
        idCoordination: filtersSelect.coordination,
        idCoordinator: filtersSelect.coordinator
      };
      await api.makeHttpRequest({
        url: `/approval/timers/professional/${idProfessional}`,
        params
      }).then((approvals) => {
        setFilteredApprovalList(prevState => {
          const filteredApprovalListAux = { ...prevState }; // Crie uma cópia do estado anterior
          const indexCreated = filteredApprovalListAux.created.findIndex(item => item.user.idUser === idProfessional);
          const indexApproved = filteredApprovalListAux.approved.findIndex(item => item.user.idUser === idProfessional);

          if (indexCreated !== -1) {
            filteredApprovalListAux.created[indexCreated].timers = approvals.created[0].timers;
          }
          if (indexApproved !== -1) {
            filteredApprovalListAux.approved[indexApproved].timers = approvals.approved[0].timers;
          }
          return filteredApprovalListAux;
        });

        setApprovalList(prevState => {
          const approvalListAux = { ...prevState }; // Crie uma cópia do estado anterior
          const indexCreated = approvalListAux.created.findIndex(item => item.user.idUser === idProfessional);
          const indexApproved = approvalListAux.approved.findIndex(item => item.user.idUser === idProfessional);

          if (indexCreated !== -1) {
            approvalListAux.created[indexCreated].timers = approvals.created[0].timers;
          }
          if (indexApproved !== -1) {
            approvalListAux.approved[indexApproved].timers = approvals.approved[0].timers;
          }
          return approvalListAux;
        });

        setProfessionalsNotReload(prevState => [...prevState, idProfessional]);
      })
    //}
  }

  return (
    <>
      <Loading isLoading={loading} />
      <div className="d-flex flex-column h-100 pl-3 pr-3">
        <AdminTitle icon={ApprovalIcon} title="Aprovações" />
        <SearchBar>
          <div className="search-bar">
            <input
              type="text"
              placeholder="Buscar por Atividade, Demanda, Cliente e Projeto nos Resultados"
              onChange={e => handleSearchFilter(e.target.value)}
              value={search}
            />
            <span className="icon-search">
              <RiSearchLine size="24px" color="#4D4C4D" />
            </span>

            {user?.role?.type === ERoles.ADMIN && (
              <Link to='/admin/aprovacoes/logs' className="kt-nav__item" style={{minWidth:"140px"}}>
                <span className="kt-nav__link">
                  <MdVisibility size={20} />
                  <span className="kt-nav__link-text">Logs</span>
                </span>
              </Link>
            )}
          </div>
        </SearchBar>
        <Filters
          reload={reload}
          onSubmit={onSubmit}
          setReload={setReload}
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
          loading={loading}
          setLoading={setLoading}
          searchText={search} // Passando o campo search como searchText para Filters
        />
        <TabsContainer>
          <Tabs activeKey={key} transition={false} onSelect={k => setKey(k)}>
            <Tab eventKey="pendentes" title="Pendentes">
              <Card>
                <Card.Body>
                  <div className="user-list">
                    {filteredApprovalList?.created?.length > 0 &&
                      filteredApprovalList.created.sort((a, b) => a.user.name.localeCompare(b.user.name)).map((item, index) => (
                        <ApprovalCard
                          key={'approval_'+index}
                          user={item.user.idUser}
                          title={item.user.name}
                          noteForBilling={item.user.noteForBilling}
                          totalTime={item}
                          timerList={item.timers.filter(
                            x => x.idTimerStatus !== 2
                          )}
                          hasTimerWithoutProposal={item.timers.some(
                            t => !t.idBusinessProposalValue
                          )}
                          hasTimerWithoutDemand={false}
                          hasTimerWithoutLoadedMinutes={false}
                          setLoading={setLoading}
                          reload={reload}
                          setReload={setReload}
                          proposalOptions={proposalOptions}
                          setProfessionalsNotReload={setProfessionalsNotReload}
                          handleAccordionExpand={handleAccordionExpand}
                        />
                      ))}
                  </div>
                </Card.Body>
              </Card>
            </Tab>
            <Tab eventKey="aprovados" title="Aprovados">
              <Card>
                <Card.Body>
                  <div className="user-list">
                    {filteredApprovalList?.approved?.length > 0 &&
                      filteredApprovalList.approved.sort((a, b) => a.user.name.localeCompare(b.user.name)).map((item, index) => (
                        <ApprovalCard
                          key={'approved_'+index}
                          user={item.user.idUser}
                          title={item.user.name}
                          noteForBilling={item.user.noteForBilling}
                          totalTime={item}
                          timerList={item.timers.filter(
                            x => x.idTimerStatus === 2
                          )}
                          hasTimerWithoutProposal={item.timers.some(
                            t => !t.idBusinessProposalValue
                          )}
                          hasTimerWithoutDemand={false}
                          hasTimerWithoutLoadedMinutes={false}
                          setLoading={setLoading}
                          reload={reload}
                          setReload={setReload}
                          approvedTab
                          proposalOptions={proposalOptions}
                          handleAccordionExpand={handleAccordionExpand}
                          setProfessionalsNotReload={setProfessionalsNotReload}
                          reloadPage={handleAccordionExpand}
                        />
                      ))}
                  </div>
                </Card.Body>
              </Card>
            </Tab>
            <Tab eventKey="factory" title="Fábrica">
              <Card>
                <Card.Body>
                  <div className="user-list">
                    {filteredApprovalList?.factory?.length > 0 &&
                      filteredApprovalList.factory.map((item, index) => (
                        <ApprovalCardFactory
                          key={index}
                          title={item.client}
                          totalTime={item}
                          timerList={item.timers}
                          setLoading={setLoading}
                          setReload={setReload}
                        />
                      )
                    )}
                  </div>
                </Card.Body>
              </Card>
            </Tab>

          </Tabs>

        </TabsContainer>
      </div>
    </>
  );
};

export default Approvals;
