import { InexApiService } from "../services/InexApiService";

export async function downloadFromUrl(fileUrl, filename) {
  const api = new InexApiService();
  
  try {
    const response = await api.makeHttpRequest({
      url: `/file/download?fileName=${filename}&fileUrl=${encodeURIComponent(fileUrl)}`,
      method: "GET",
      responseType: "blob"
    });
    if (response && response.data instanceof Blob) {
      const url = window.URL.createObjectURL(response.data); 

      const a = document.createElement("a");
      a.href = url;
      a.download = filename || "download";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } else {
      console.error("A resposta não é um Blob", response);
    }
  } catch (error) {
    console.error("Erro ao tentar baixar o arquivo:", error);
  }
}
